import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewSavingScheme.css';
import Priceupdates from './Priceupdates';
import {useSelector, useDispatch  } from 'react-redux';
import { setUser } from '../../Redux/store/authSlice';
import RazorpayPayment from './RazorpayPayment';
import { toast } from "react-hot-toast";
import { setTransactionData, clearTransactionData } from '../../Redux/store/transactionSlice';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingOverlay from './LoadingOverlay'; 
import warningIcon from '../common/asset1/warning-symbol.svg';
import { setIsLoading } from '../../Redux/store/authSlice';
const Gender = Object.freeze({
  Male: 0,
  Female: 1,
  PreferNotToSay: 2,
});
 
const idProofMapping = {
  'DrivingLicense': 0,
  'PANCard': 1,
  'AadharCard': 2,
  'VoterId': 3,
  'Passport': 4,
};
 
 
const NewSavingScheme = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState(null);
  const userToken=useSelector(state => state.auth.token);
  const userID = useSelector(state => state.auth.CustomerID);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(false); 
  const [isPanVerified, setIsPanVerified] = useState(false); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [panChecked, setPanChecked] = useState(false); 
  //console.log('New Saving Token from Redux:', userToken)
  const apiUrl = process.env.REACT_APP_API_URL;
 
  const [formData, setFormData] = useState({
    customerId: userID || '',
    schemeId: '',
    schemeName: '',
    amountPerMonth: '',
    fullName: '',
    gender: '',
    altMobileNumber: '',
    email: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    dob: '',
    anniversary: '',
    idProof: '',
    idProofNumber: '',
    panNumber: ''
  });
 
  const [errors, setErrors] = useState({ gender: '', state: '' });
  const [states, setStates] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [schemes, setSchemes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [customerEnrolledSchemeId, setcustomerEnrolledSchemeId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      try {
        const response = await axios.get(`${apiUrl}/scheme/savingscheme`, {
                                         
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
       // console.log('Fetched scheme data:', response.data);
        const schemeData = response.data;
        setSchemes(schemeData.savingSchemePlans || []);
        setCountries(schemeData.countries || []);
        if (!formData.country && schemeData.countries.length > 0) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            country: schemeData.countries[0].countryName,
            countryId: schemeData.countries[0].id,
          }));
        }
      } catch (error) {
        console.error('Error fetching scheme data:', error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchData();
  }, [formData.country, userToken,  dispatch]);

  

  
  //const tokenExpiryDate = new Date(localStorage.getItem('tokenExpiry'));
//console.log('Token Expiry Date:', tokenExpiryDate);
//console.log('Current Date:', new Date());
 
  useEffect(() => {
    const fetchStates = async () => {
      dispatch(setIsLoading(true));
      try {
        const response = await axios.get(`${apiUrl}/state/country/1`, {
          
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        //console.log('Fetched states:', response.data);
        setStates(response.data || []);
      } catch (error) {
        console.error('Error fetching states:', error);
      } finally {
        dispatch(setIsLoading(false));
     }
    };
 
    fetchStates();
  }, [formData.country, userToken]);
 
  const handleSchemeChange = (e) => {
    const selectedSchemeId = e.target.value;
    const selectedScheme = schemes.find(scheme => scheme.id === parseInt(selectedSchemeId, 10));
    //console.log('Selected Scheme:', selectedScheme);
    setErrors(prevErrors => ({
      ...prevErrors,
      schemeName: ''
    }));
    if (selectedScheme) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        schemeId: selectedScheme.id,
        schemeName: selectedScheme.groupId,
        amountPerMonth: selectedScheme.monthlyInstallment,
      }));
    } else {
     
      setFormData((prevFormData) => ({
        ...prevFormData,
        schemeId: '',
        schemeName: '',
        amountPerMonth: '',
      }));
    }
  };
  const validate = () => {
    const newErrors = {};
    const amountPerMonth = parseFloat(formData.amountPerMonth) || 0;
    if (!formData.schemeName) {
      newErrors.schemeName = 'Scheme is required.';
    }
    if (!formData.fullName) {
      newErrors.fullName = 'Full Name is required.';
    }
    if (!formData.gender) {
      newErrors.gender = 'Gender is required.';
    }
    if (!formData.address) {
      newErrors.address = 'Address is required.';
    }
    if (!formData.city) {
      newErrors.city = 'City Name is required.';
    }
    if (!formData.state) {
      newErrors.state = 'State is required.';
    }
    if (!formData.pincode) {
      newErrors.pincode = 'PIN code is required.';
    }
    if (!formData.dob) {
      newErrors.dob = 'Date of Birth is required.';
    } else {
      const dob = new Date(formData.dob);
      const today = new Date();
      if (dob > today) {
        newErrors.dob = 'Date of Birth cannot be in the future.';
      }
    }
    if (formData.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.email)) {
        newErrors.email = 'Invalid email format.';
      }
    }
  
    if (amountPerMonth > 18181.83 && !formData.panNumber) {
      if (!formData.panNumber) {
        newErrors.panNumber = 'PAN Number is required.';
      } else if (formData.panNumber.length !== 10) {
        newErrors.panNumber = 'PAN number must be exactly 10 characters';
      }
    }
    setErrors(newErrors);
    console.log('Validation errors:', newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(`Changing ${id} to ${value}`);

    setErrors(prevErrors => ({
      ...prevErrors,
      [id]: ''
    }));

    if (id === 'idProofNumber') {
      const idProofType = formData.idProof;
      let validInput = value;

      switch (idProofType) {
        case 'AadharCard':
          validInput = value.replace(/\D/g, '');
          if (validInput.length > 12) validInput = validInput.substring(0, 12);
          if (validInput.length !== 12) {
            setErrors(prevErrors => ({
              ...prevErrors,
              idProofNumber: 'Aadhaar number must be exactly 12 digits'
            }));
          }
          break;
        case 'PANCard':
          validInput = value.replace(/[^A-Z0-9]/gi, '');
          if (validInput.length > 10) validInput = validInput.substring(0, 10);
          if (validInput.length !== 10) {
            setErrors(prevErrors => ({
              ...prevErrors,
              idProofNumber: 'PAN number must be exactly 10 characters'
            }));
          }
          break;
        case 'VoterId':
          validInput = value.replace(/[^A-Z0-9]/gi, '');
          if (validInput.length > 10) validInput = validInput.substring(0, 10);
          if (validInput.length !== 10) {
            setErrors(prevErrors => ({
              ...prevErrors,
              idProofNumber: 'Voter ID must be exactly 10 characters'
            }));
          }
          break;
        case 'Passport':
          validInput = value.replace(/[^A-Z0-9]/gi, '');
          if (validInput.length > 8) validInput = validInput.substring(0, 8);
          if (validInput.length !== 8) {
            setErrors(prevErrors => ({
              ...prevErrors,
              idProofNumber: 'Passport number must be exactly 8 characters'
            }));
          }
          break;
        default:
          validInput = value;
          break;
      }

      setFormData(prevFormData => ({
        ...prevFormData,
        idProofNumber: validInput
      }));

    } else if (id === 'altMobileNumber') {
      let numericValue = value.replace(/^\+91|[^0-9]/g, '');
      if (numericValue && numericValue.length !== 10) {
        setErrors(prevErrors => ({
          ...prevErrors,
          altMobileNumber: 'Alternative mobile number must be exactly 10 digits'
        }));
      }

      if (value.startsWith('+91')) {
        numericValue = numericValue.substring(0, 10);
        setFormData(prevFormData => ({ ...prevFormData, altMobileNumber: `+91${numericValue}` }));
      } else {
        numericValue = numericValue.substring(0, 10);
        setFormData(prevFormData => ({ ...prevFormData, altMobileNumber: `+91${numericValue}` }));
      }

    } else if (id === 'pincode') {
      const numericValue = value.replace(/\D/g, '');
      if (numericValue.length !== 6) {
        setErrors(prevErrors => ({
          ...prevErrors,
          pincode: 'Pincode must be exactly 6 digits'
        }));
      }
      setFormData(prevFormData => ({ ...prevFormData, pincode: numericValue }));

    } else if (id === 'panNumber') {
      const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 10);
      if (alphanumericValue.length !== 10) {
        setErrors(prevErrors => ({
          ...prevErrors,
          panNumber: 'PAN number must be exactly 10 characters'
        }));
      }

      setFormData(prevFormData => ({
        ...prevFormData,
        panNumber: alphanumericValue
      }));

    } else if (id === 'schemeName') {
      const selectedScheme = schemes.find(scheme => scheme.groupId === value);
      if (selectedScheme) {
        setFormData(prevFormData => ({
          ...prevFormData,
          schemeName: selectedScheme.groupId,
          schemeId: selectedScheme.id,
          amountPerMonth: selectedScheme.monthlyInstallment
        }));
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          schemeName: '',
          schemeId: '',
          amountPerMonth: ''
        }));
      }

    } else if (id === 'country') {
      const selectedCountry = countries.find(country => country.id === parseInt(value, 10));
      if (selectedCountry) {
        setFormData(prevFormData => ({
          ...prevFormData,
          country: selectedCountry.countryName,
          countryId: selectedCountry.id
        }));
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          [id]: value,
        }));
      }

    } else if (id === 'fullName') {
    
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData(prevFormData => ({
          ...prevFormData,
          fullName: value
        }));
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          fullName: 'Full Name can only contain alphabets'
        }));
      }

    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [id]: value,
      }));
    }
};

  const verifyPAN = async () => {
    if (formData.panNumber.length === 10) {  
      try {
        const response = await axios.post(
          `${apiUrl}/pan`,
        
          { pan: formData.panNumber }, 
          { 
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
       
        const { data } = response;

        if (data === true) {
          setIsPanVerified(true);
          toast.success('PAN verified successfully!');
        } else {
          setIsPanVerified(false);
          toast.error('Invalid PAN number. Please try again.');
        }
      } catch (error) {
        setIsPanVerified(false);
        toast.error('Error verifying PAN. Please try again later.');
      }
    } else {
      toast.error('PAN number must be exactly 10 characters.');
    }
  };

  
  const handlePanCheckbox = (e) => {
    const isChecked = e.target.checked;
    setPanChecked(isChecked);
    if (parseFloat(formData.amountPerMonth) > 18181.83 && isChecked) {
      verifyPAN();
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    if (!validate()) {
      return;
    }
    const amount = parseFloat(formData.amountPerMonth);
    if (amount > 18181.83) {
      if (!isPanVerified) {
        toast.error('Please verify your PAN before submitting for this amount.');
        return;
      }
      if (!panChecked) {
        toast.error('Please check the PAN verification box before submitting.');
        return;
      }
    }
  
    setIsSubmitting(true);
    dispatch(setIsLoading(true));
    const payload = {
      customerId: formData.customerId,
      schemeId: formData.schemeId,
      schemeName: formData.schemeName,
      amountPerMonth: parseFloat(formData.amountPerMonth),
      fullName: formData.fullName,
      gender: formData.gender !== '' ? parseInt(formData.gender, 10) : null,
      alternateMobileNumber: formData.altMobileNumber.replace(/^\+91/, '') || null,
      email: formData.email || null,
      address: formData.address,
      city: formData.city,
      stateId: states.find((state) => state.stateName === formData.state)?.id,
      countryId: countries.find((country) => country.countryName === formData.country)?.id,
      pinCode: formData.pincode,
      dateOfBirth: formData.dob ? new Date(formData.dob).toISOString() : null,
      dateOfAnniversary: formData.anniversary ? new Date(formData.anniversary).toISOString() : null,
      selectIdProof: idProofMapping[formData.idProof] || null,
      idProofNumber: formData.idProofNumber,
      ...(parseInt(formData.amountPerMonth, 10) > 18181.83 && { panNumber: formData.panNumber }),
    };
  
    try {
      const response = await fetch(`${apiUrl}/enrolledScheme`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(payload),
      });
      console.log('Form submission detail:', payload);
      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(responseText);
      }
      
      const responseData = await response.json();
      setcustomerEnrolledSchemeId(responseData.customerEnrolledSchemeId);
      console.log('Customer Enrolled scheme ID received from payload:', responseData.customerEnrolledSchemeId);
      setSuccessMessage('Form submitted successfully!');
      setFetchDataTrigger(prev => !prev);
      //alert('Form submitted successfully');
      setSuccessMessage('You have enrolled the scheme successfully. Kindly check your scheme details in My Saving Scheme');
      setFormSubmitted(true);
      setPaymentReady(true);
      handleClickPayNow(responseData.customerEnrolledSchemeId);
    } catch (error) {
      alert(`Error submitting the form: ${error.message}`);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
      dispatch(setIsLoading(false));
    }
  };
  
  
  const createTransaction = async (data, userToken) => {
    try {
      console.log('Creating transaction with data:', data);
      const response = await axios.post(`${apiUrl}/transaction/addtransaction`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating transaction:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  const handleClickPayNow = async (customerEnrolledSchemeId, paymentSuccess, status) => {
    const selectedScheme = schemes.find((scheme) => scheme.id === parseInt(formData.schemeId, 10));
   
  
  
    if (!selectedScheme) {
      toast.error('Scheme data is not available. Please try again later.');
      return;
    }
  
    const transactionId = uuidv4();
  
    const paymentTransactionDto = {
      schemeId: formData.schemeId,
      transactionId: transactionId,
      customerEnrolledSchemeId: customerEnrolledSchemeId,
      schemeName: selectedScheme.groupId,
      amount: parseInt(selectedScheme.monthlyInstallment),
      customerId: formData.customerId,
      paymentStatus:undefined,
      paymentMethod:undefined,
      GatewayPaymentId: null,
      datePaid: new Date().toISOString(),
      //totalAmount: parseInt(selectedScheme.monthlyInstallment),
    };
  
    try {
      const transactionResponse = await createTransaction(paymentTransactionDto, userToken);
      if (transactionResponse) {
        setPaymentData(transactionResponse);
        dispatch(setTransactionData(transactionResponse));
        initiateRazorpayPayment(transactionResponse);
        console.log('Inspecting-scheme:', selectedScheme);
        console.log('Transaction created:', transactionResponse);
        const paymentTransactionId = transactionResponse.paymentTransactionId;
        console.log('PaymentTransactionId ID from API:', paymentTransactionId);
  
        
       
      } else {
        toast.error('Failed to create transaction. Please try again.');
      }
    } catch (error) {
      
    }
  };
  
  const initiateRazorpayPayment = (transactionData) => {
     setPaymentData(transactionData);
    if (window.Razorpay) {
      delete window.Razorpay;
    }
    
    const options = {
      key: '  rzp_test_lAjomxXGqI58Fb',
      amount: transactionData.amount * 100,
      currency: 'INR',
      name: 'RAM THANGANAGAI MALIGAI',
      description: 'Transaction Payment',
      order_id: transactionData.transactionId,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      notes: {
        transaction_id: transactionData.transactionId,
      },
    };
  
    const rzp = new window.Razorpay(options);
    rzp.on('payment.failed', handlePaymentFailure);
    rzp.open();
  };
  
  const handlePaymentSuccess = async (response) => {
    try {
      console.log('Payment Successful:', response);
      const { razorpay_payment_id } = response;
      
      if (paymentData) {
        console.log('Payment Data for update transaction:', paymentData);
        const updatedTransaction = await updateTransactionStatus(paymentData.paymentTransactionId, 'success', true, razorpay_payment_id);
        if (updatedTransaction) {
          console.log('Updated transaction data after payment success:', updatedTransaction);
          setFetchDataTrigger(prev => !prev);
          setPaymentData(null);
        } else {
          console.error('Failed to update transaction data after payment success.');
        }
        toast.success('Payment was successful!');
        setPaymentData(null);
        navigate('/home/my-saving-scheme', { state: { refresh: true } });

        dispatch(clearTransactionData());
      } else {
        console.error('Payment data is missing. Cannot update transaction status.');
      }
    } catch (error) {
      console.error('Error handling payment success:', error);
      toast.error('An error occurred while processing the payment success.');
    }
  };
  
  const handlePaymentFailure = async (response) => {
    try {
      console.log('Payment Failed:', response);
      await updateTransactionStatus(paymentData?.paymentTransactionId, 'failure', false, 'failure');
      if (window.Razorpay) {
    delete window.Razorpay;
  }
      toast.error('Payment failed, please try again.');
      navigate('/home/new-saving-scheme');
      dispatch(clearTransactionData());
    } catch (error) {
      console.error('Error handling payment failure:', error);
      toast.error('An error occurred while processing the payment failure.');
    }
  };

  
  const updateTransactionStatus = async (paymentTransactionId, status, paymentSuccess, razorpay_payment_id = null) => {
    console.log('updateTransactionStatus function called');
    const selectedScheme = schemes.find((scheme) => scheme.id === parseInt(formData.schemeId, 10));
    const transactionPayload = {
      id: paymentTransactionId,
      schemeId: formData.schemeId,
      transactionId: paymentData?.transactionId || null,
      customerId: formData.customerId,
      amount: parseInt(selectedScheme.monthlyInstallment),
      schemeName: formData.schemeName,
      //status: paymentSuccess ? PaymentStatus.Completed : PaymentStatus.Failed,
      paymentStatus:undefined,
      datePaid: new Date().toISOString(),
      //modeOfPayment:0,
      paymentMethod:undefined,
     // totalAmount: parseInt(selectedScheme.monthlyInstallment),
     GatewayPaymentId: razorpay_payment_id || paymentData?.razorpay_payment_id || null,
    };
  
    console.log('Data being sent for Transactionupdate:', transactionPayload);
    try {
      const response = await axios.post(
        `${apiUrl}/transaction/${paymentTransactionId}`,
        transactionPayload,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Transaction status updated:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating transaction status:', error);
      return null;
    }
    
  };
  

  const today = new Date().toISOString().split('T')[0];

 
  if (loading) {
    return <LoadingOverlay/>;
}
  return (
    <div className='whole-newscheme-cont'>
      <Priceupdates />
      {!formSubmitted ? (
      <form className='new-scheme-outer-cont' onSubmit={handleSubmit}>
        <div className='join-title'>Join a New Scheme</div>
 
        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='schemeName'>Scheme Name *</label>
            <select id='schemeName' className={`form-input ${errors.schemeName ? 'error' : ''}`} value={formData.schemeId} onChange={handleSchemeChange}>
              <option value=''>Select Scheme</option>
                    {schemes.map(scheme => (
                     <option key={scheme.id} value={scheme.id}>{scheme.groupId}</option>
            ))}
          </select>
          {errors.schemeName &&    <div className="error-reg-icon-out">
            <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
             <div className='error-add-new-scheme'>{errors.schemeName}</div> </div>}    
          </div>
 
          <div className='rhs-cont'>
            <label htmlFor='amount-per-month'>Amount per month</label>
            <input type='text' id='amountPerMonth' className={`form-input ${errors.amountPerMonth ? 'error' : ''}`} value={formData.amountPerMonth} placeholder='Select a scheme to know the amount' disabled />
          </div>
        </div>
 
        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='fullName'>Full Name *</label>
            <input type='text' id='fullName' className={`form-input ${errors.fullName ? 'error' : ''}`} placeholder='Full Name' value={formData.fullName} onChange={handleChange} />
            {errors.fullName &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <div className='error-add-new-scheme'>{errors.fullName}</div></div>}
          </div>
 
          <div className='rhs-cont'>
            <label htmlFor='gender'>Gender *</label>
            <select id='gender'   className={`select-box ${errors.gender ? 'error' : ''}`} value={formData.gender} onChange={handleChange}>
              <option value=''>Select Gender</option>
              <option value={Gender.Male}>Male</option>
              <option value={Gender.Female}>Female</option>
              <option value={Gender.PreferNotToSay}>Prefer Not to Say</option>
            </select>
            {errors.gender &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <div className='error-add-new-scheme'>{errors.gender}</div></div>}
          </div>
        </div>
 
        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='altMobileNumber'>Alternative Mobile Number</label>
            <input
                 type='text'
               id='altMobileNumber'
               className='form-input'
                        placeholder='+91'
            value={formData.altMobileNumber.startsWith('+91') ? formData.altMobileNumber : `+91${formData.altMobileNumber}`}
             onChange={handleChange}
             maxLength={13}
               />
                 {errors.altMobileNumber &&    <div className="error-reg-icon-out">
                  <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
                  <div className='error-add-new-scheme'>{errors.altMobileNumber}</div></div>}
          </div>
 
          <div className='rhs-cont'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' className='form-input' placeholder='Type your email' value={formData.email} onChange={handleChange} />
            {errors.email &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" /><span className='error-message'>{errors.email}</span></div>}
          </div>
        </div>
 
        <div className='address-out'>
          <div className='scheme-address-cont'>
            <label htmlFor='address'>Address *</label>
            <textarea id='address' className={`form-input ${errors.address ? 'error' : ''}`} placeholder='' value={formData.address} onChange={handleChange}></textarea>
            {errors.address &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <div className='error-add-new-scheme'>{errors.address}</div></div>}
          </div>
        </div>
 
         <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='city'>City *</label>
            <input
                       type='text'
                         id='city'
                  className={`form-input ${errors.city ? 'error' : ''}`}
                     placeholder='Enter city name'
                    value={formData.city}
                       onChange={handleChange}
                                   />
                                    {errors.city &&    <div className="error-reg-icon-out">
                                      <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
                                      <div className='error-add-new-scheme'>{errors.city}</div></div>}
          </div>  
 
          <div className='rhs-cont'>
            <label htmlFor='state'>State *</label>
            <select id='state' className={`select-box ${errors.state ? 'error' : ''}`} value={formData.state} onChange={handleChange}>
              <option value=''>Select State</option>
              {states.map(state => (
                <option key={state.id} value={state.stateName}>
                  {state.stateName}
                </option>
              ))}
            </select>
            {errors.state &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <div className='error-add-new-scheme'>{errors.state}</div></div>}
          </div>
        </div>
 
        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='pincode'>Pin code *</label>
            <input type='text' id='pincode' className={`form-input ${errors.pincode ? 'error' : ''}`}
             placeholder='Pincode' value={formData.pincode} onChange={handleChange}  maxLength={6} />
            {errors.pincode &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <div className='error-add-new-scheme'>{errors.pincode}</div></div>}
          </div>
 
          <div className='rhs-cont'>
            <label htmlFor='country'>Country *</label>
            <select id='country' className='form-input' value={formData.country} onChange={handleChange}>
              {countries.map(country => (
                <option key={country.id} value={country.countryName}>
                  {country.countryName}
                </option>
              ))}
            </select>
          </div>
        </div>
 
        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='dob'>Date of Birth *</label>
            <input type='date' id='dob' className={`form-input ${errors.dob ? 'error' : ''}`} placeholder='Date of Birth' value={formData.dob} max={today}  onChange={handleChange} />
            {errors.dob &&    <div className="error-reg-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <div className='error-add-new-scheme'>{errors.dob}</div></div>}
          </div>
 
          <div className='rhs-cont'>
            <label htmlFor='anniversary'>Date of Anniversary</label>
            <input type='date' id='anniversary' className='form-input' placeholder='Date of Anniversary' value={formData.anniversary} max={today}  onChange={handleChange} />
          </div>
        </div>
 
        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='idProof'>ID Proof</label>
            <select id='idProof' className='form-input' value={formData.idProof} onChange={handleChange}>
              <option value=''>Select ID Proof</option>
              <option value='DrivingLicense'>Driving License</option>
              {parseFloat(formData.amountPerMonth) <= 18181.83 && (
              <option value='PANCard'>PAN Card</option>
            )}
              <option value='AadharCard'>Aadhar Card</option>
              <option value='VoterId'>Voter ID</option>
              <option value='Passport'>Passport</option>
            </select>
            
          </div>
 
          <div className='rhs-cont'>
            <label htmlFor='idProofNumber'>ID Proof Number</label>
            <input type='text' id='idProofNumber' className='form-input' placeholder='Enter ID Proof Number' value={formData.idProofNumber} onChange={handleChange} />
            {errors.idProofNumber &&    <div className="error-with-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-add-new-scheme">{errors.idProofNumber}</span></div>}
          </div>
          </div>
          {parseFloat(formData.amountPerMonth) > 18181.83 && (
  <div className="scheme-name-out">
    <div className="scheme-name-cont">
      <label htmlFor="pan-number">PAN number *</label>

     
      <div className="pan-input-container">
        <input
          type="text"
          id="panNumber"
          className={`form-input ${errors.panNumber ? 'error' : ''}`}
          placeholder="Pan Number"
          value={formData.panNumber}
          onChange={(e) => setFormData({ ...formData, panNumber: e.target.value })}
          maxLength={10}
        />
 
      
        <div className="pan-verify-checkbox">
          <label>
            <input
              type="checkbox"
              checked={panChecked}
              onChange={handlePanCheckbox}
            />
            Verify PAN
          </label>
        </div>
      </div>
      {errors.panNumber && (
        <div className="error-reg-icon-out">
          <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
          <div className="error-add-new-scheme">{errors.panNumber}</div>
        </div>
      )}

    
      <div className="two-lakh-note">
        Note: If the scheme amount is above ₹2 Lakhs, PAN number is must for verification purposes.
      </div>

    
      {isPanVerified && (
        <div className="pan-verified-message">
          <CheckCircleOutlineIcon style={{ color: 'green', fontSize: '1.5rem' }} />
          <span>PAN Verified</span>
        </div>
      )}
    </div>
  </div>
)}
      {/*<button type="submit" disabled={isSubmitting}>
           {isSubmitting ? '' : ''} 
         </button>  */} 
         
       <button type='submit' className='btn-sub-nscheme' onClick={() => handleClickPayNow}>Submit</button>     </form>
       ) : (
         <div className='success-message'>
           <CheckCircleOutlineIcon style={{ color: 'green', fontSize: '2rem' }} />
           <p>{successMessage}</p>
         </div>
       )}
 
     {paymentData && (
               <RazorpayPayment
                   transactionData={paymentData}
                   onPaymentSuccess={handlePaymentSuccess}
                   onPaymentFailure={handlePaymentFailure}
               />
           )}
   </div>
 );
};

export default NewSavingScheme;


