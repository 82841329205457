import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearTransactionData } from '../../Redux/store/transactionSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RazorpayPayment = ({ transactionData, onPaymentSuccess, onPaymentFailure }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [paymentInitialized, setPaymentInitialized] = useState(false); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRole = useSelector((state) => state.auth.role);

    useEffect(() => {
        const loadRazorpayScript = () => {
            return new Promise((resolve, reject) => {
                if (document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
                    resolve(true);
                    return;
                }
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = () => {
                    setScriptLoaded(true);
                    resolve(true);
                };
                script.onerror = () => reject(new Error('Failed to load Razorpay script'));
                document.body.appendChild(script);
            });
        };

        loadRazorpayScript().catch((error) =>
            console.error('Error loading Razorpay script:', error)
        );

        return () => {
            const script = document.querySelector(
                'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
            );
            if (script) document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (transactionData && !paymentInitialized && scriptLoaded) {
            initiatePayment();
        }
    }, [transactionData, scriptLoaded, paymentInitialized]);

    const initiatePayment = () => {
        setPaymentInitialized(true); 
        const options = {
            key: 'rzp_test_lAjomxXGqI58Fb',
            amount: transactionData.amount * 100,
            currency: 'INR',
            name: 'Ram Thanganagai Malligai',
            description: 'Scheme Payment for chitfund',
            handler: (response) => {
                onPaymentSuccess(response);
                dispatch(clearTransactionData());
                navigate(userRole === 'admin' ? '/admin/home/add-new-scheme' : '/home/my-saving-scheme');
            },
            prefill: {
                name: transactionData.name,
                email: transactionData.email,
                contact: transactionData.contact,
            },
            notes: {
                scheme_uuid: transactionData.scheme_uuid,
                schemeName: transactionData.schemeName,
            },
            theme: {
                color: '#3399cc',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();

        rzp.on('payment.failed', (response) => {
            onPaymentFailure(response);
            dispatch(clearTransactionData());
            navigate(userRole === 'admin' ? '/admin/home/add-new-scheme' : '/home/my-saving-scheme');
        });
    };

    return null;
};

export default RazorpayPayment;
