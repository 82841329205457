/*{import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    transactionData: null, 
};


const transactionSlice = createSlice({
  name: 'transaction',
  /*initialState: {
    transactionData: null,
  },  */    /*{}
  initialState,
  reducers: {
    setTransactionData(state, action) {
      state.transactionData = action.payload;
    },
    clearTransactionData(state) {
      state.transactionData = null;
    },
  },
});

export const { setTransactionData, clearTransactionData } = transactionSlice.actions;
export default transactionSlice.reducer;  } */
// transactionSlice.js
// transactionSlice.js
// transactionSlice.js



// here is the transaction slice

import { createSlice } from '@reduxjs/toolkit';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transactionData: null, 
  },
  reducers: {
    setTransactionData: (state, action) => {
      state.transactionData = action.payload;
    },
    clearTransactionData: (state) => {
      state.transactionData = null;
    },
  },
});

export const { setTransactionData, clearTransactionData } = transactionSlice.actions;
export default transactionSlice.reducer;
