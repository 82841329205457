import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import RazorpayPayment from './RazorpayPayment';
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionData, clearTransactionData } from '../../Redux/store/transactionSlice';
import { useNavigate } from 'react-router-dom';
import './SchemeInformation.css';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import LoadingOverlay from './LoadingOverlay'; 
import zeroFillIcon from './asset2/zero-fill-icon.svg';
import { setIsLoading } from '../../Redux/store/authSlice';
const Gender = Object.freeze({
    Male: 0,
    Female: 1,
    PreferNotToSay: 2,
});

const SchemeTicket = () => {
    const [schemeData, setSchemeData] = useState([]);
    const [paymentData, setPaymentData] = useState(null);
    const [fetchDataTrigger, setFetchDataTrigger] = useState(false); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userToken = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.CustomerID);
    const token = useSelector(state => state.auth?.user?.token) || localStorage.getItem('accessToken');
    const [loading, setLoading] = useState(true);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
    const isLoading = useSelector(state => state.auth.isLoading);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation(); 
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        setLoading(true);
    }, [token, navigate, userID, userToken, fetchDataTrigger]);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1133);
        };
        window.addEventListener('resize', handleResize);
        handleResize(); 
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const fetchSchemeData = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${apiUrl}/enrolledScheme/${userID}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response.status === 401) {
                toast.error("Unauthorized access. Please log in again.");
                navigate('/signin');
                return;
            }

            const data = response.data;
            //console.log('Fetched scheme data:', data);
            data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
           setSchemeData(data);
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error(`Error fetching scheme data: ${error.message}`);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

   
    useEffect(() => {
        if (!token) {
            toast.error("Token not found. Please log in.");
            navigate('/signin');
            return;
        }

        if (location.state?.refresh) {
            console.log("Fetching my saving schemes based on location state...");
            fetchSchemeData();  
        } else {
            fetchSchemeData();  
        }
    }, [token,navigate,location.state,userToken,fetchDataTrigger])

    

    const handleClickPayNow = async (scheme) => {
        if (!scheme) {
            toast.error('Scheme data is not available. Please try again later.');
            return;
        }

        const transactionId = uuidv4();
        const transactionData = {
            schemeId: scheme.schemeId,
            transactionId: transactionId,
            schemeName: scheme.groupId,
            totalAmount: scheme.amountToPay,
            amount: scheme.amountToPay,
            customerId: scheme.customerId,
            name: scheme.fullName,
            contact: scheme.alternateMobileNumber,
            customerEnrolledSchemeId: scheme.customerEnrolledSchemeId,
            datePaid: new Date().toISOString(),
            GatewayPaymentId: null,
            amountToPay: scheme.amountToPay,
            paymentStatus: undefined,
            paymentMethod: undefined,
        };

        try {
            const transactionResponse = await createTransaction(transactionData, userToken);
            if (transactionResponse) {
                setPaymentData(transactionResponse);
                dispatch(setTransactionData(transactionResponse));
                //initiateRazorpayPayment(transactionResponse);
            } else {
                toast.error('Failed to create transaction. Please try again.');
            }
        } catch (error) {
            toast.error('Error creating transaction. Please try again.');
        }
    };

    const createTransaction = async (data, userToken) => {
        try {
            const response = await axios.post(`${apiUrl}/transaction/addtransaction`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error creating transaction:', error.response ? error.response.data : error.message);
            throw error;
        }
    };


    const handlePaymentSuccess = async (response) => {
        try {
            const { razorpay_payment_id } = response;
            if (paymentData) {
                dispatch(setIsLoading(true));
                const updatedTransaction = await updateTransactionStatus(paymentData.paymentTransactionId, 'success', true, razorpay_payment_id);
                if (updatedTransaction) {
                    toast.success('Payment was successful!');
                    setIsPaymentSuccessful(true);
                    setFetchDataTrigger(prev => !prev); 
                    navigate('/home/my-saving-scheme');
                    setIsPaymentSuccessful(false);
                    dispatch(clearTransactionData());
                    setPaymentData(null); 
                } else {
                   console.error('Failed to update transaction data after payment success.');
                }
            }
        } catch (error) {
            console.error('Error handling payment success:', error);
            toast.error('An error occurred while processing the payment success.');
        }finally {
            dispatch(setIsLoading(false));
        }
    };

    const handlePaymentFailure = async (response) => {
        try {
            setLoading(true);
            await updateTransactionStatus(paymentData?.paymentTransactionId, 'failure', false, 'failure');
            setPaymentData(null);
            toast.error('Payment failed, please try again.');
            navigate('/home/my-saving-scheme');
            dispatch(clearTransactionData());
        } catch (error) {
            console.error('Error handling payment failure:', error);
            toast.error('An error occurred while processing the payment failure.');
        }finally {
            setLoading(false);
        }
    };

    const updateTransactionStatus = async (paymentTransactionId, status, paymentSuccess, razorpay_payment_id = null) => {
        console.log('Payment Data:', paymentData);  
        const transactionPayload = {
            id: paymentTransactionId,
            schemeId: paymentData.schemeId,
            transactionId: paymentData.transactionId,
            customerId: paymentData.customerId,
            amount:paymentData.amount,
            schemeName: paymentData.schemeName,
            //numberOfInstallments:'',
           // status: paymentSuccess ? PaymentStatus.Completed : PaymentStatus.Failed,
            paymentStatus:undefined,
            datePaid: new Date().toISOString(),
            paymentMethod:undefined, 
            //totalAmount: paymentData.amountToPay,
           // modeOfPayment:0,
            GatewayPaymentId: razorpay_payment_id || paymentData?.razorpay_payment_id || null,
        };
        console.log("Transaction upload :",transactionPayload)
        try {
            const response = await axios.post(
                `${apiUrl}/transaction/${paymentTransactionId}`,
                transactionPayload,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error updating transaction status:', error);
            return null;
        }
    };

    const handleClick = () => {
        navigate('/home/new-saving-scheme'); 
    };

    const viewDetailHandleClick = (customerEnrolledSchemeId) => {
        navigate('/home/view-detail-scheme', { state: { customerEnrolledSchemeId } }); 
        //console.log(`Navigating to details page of SchemeTicket.js: ${customerEnrolledSchemeId}`);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day} / ${month} / ${year}`;
    };
  
    if (isLoading) {
        return <LoadingOverlay/>;
    }
    if (schemeData.length === 0) {
        return      <div className='scheme-ticket-cont'>
        <div className='manage-sav-cont'>
            <h2>Manage Savings Schemes</h2>
            <button className='new-scheme-btn' onClick={handleClick}>
                New Scheme
                <FontAwesomeIcon icon={faPlus} className='plus-icon' />
            </button>
        </div>
        <div className='length-fill-cont-out'>
  <div className='length-fill-cont'>
  <img src={zeroFillIcon} alt="ZeroFillIcon" className="zero-fill-icon" />
  <div className='zero-fill-description'>
  Welcome to <span style={{ color: '#5A189A', fontWeight: '700' }}>RTM Savings Scheme!</span> Ready to 
  <span style={{ fontWeight: '700' }}> grow</span> your <span style={{ fontWeight: '700' }}>savings</span>? Join a
   <span style={{ color: '#5A189A', fontWeight: '700' }}> Scheme</span> today and take your first step toward smart financial planning! </div>
            <button className='new-scheme-alert' onClick={handleClick}>Join a New Scheme.</button> </div>
                      </div></div>;
    }

    return (
        <div className='scheme-ticket-cont'>
             <div className='manage-sav-cont'>
              <h2>Manage Savings Schemes</h2>   {!isMobile && 
                <button className='new-scheme-btn' onClick={handleClick}>
                    New Scheme
                    <FontAwesomeIcon icon={faPlus} className='plus-icon' />
                </button> }
            </div>

            {schemeData.map((scheme, index) => (
                <div key={`${scheme.schemeId}-${index}`} className="member-scheme">
                    <div className="maturity-cont">
                        <div className='due-cont'>
                            <div className='due-filled'>
                                <span>{scheme.noOfPaidInstallments}</span>
                                <span>{scheme.totalMonths}</span>
                            </div>
                            <div className='mat-date-cont'>
                                <h3>Maturity Date</h3>
                                <h2>{formatDate(scheme.maturityDate)}</h2>
                            </div>
                        </div>
                        <div className="scheme-container">
                            <div className="scheme-detail">
                                <span className="label">Scheme Name</span>
                                <span className="value">{scheme.groupId}</span>
                            </div>
                            <div className="scheme-detail">
                                <span className="label">Scheme No.</span>
                                <span className="value">{scheme.schemeId}</span>
                            </div>
                            <div className="scheme-detail">
                                <span className="label">Amount</span>
                                <span className="value">₹ {scheme.amount} /-</span>
                            </div>

                            <div className="scheme-detail">
                                <span className="label">Group Code:</span>
                                <span className="value">{scheme.groupId}</span>
                            </div>
                        </div>
                        <div className='pay-detail'>
                            <h3>Payment Status:</h3>
                            <p className={scheme.paymentStatus === 'Paid' ? 'paid-color' : 'unpaid-color'}>
                                   {scheme.paymentStatus}
                             </p>
                        </div>
                    </div>

                    <div className="id-cont">
                        <div className='profile-cont'>
                            <div className='profile-out-cont'>
                                <FontAwesomeIcon icon={faUser} className='profile-icon' />
                            </div>
                            <div className='profile-det'>
                                <p>{scheme.fullName}</p>
                                <p>{scheme.alternateMobileNumber}</p>
                                <p>{Object.keys(Gender).find(key => Gender[key] === scheme.gender)}</p>
                            </div>
                        </div>
                        <div className='btn-group-pay'>
                            <button className='btn-view-details' onClick={() => viewDetailHandleClick(scheme.customerEnrolledSchemeId) 
                            } disabled={scheme.noOfPaidInstallments === 0}>View Details</button>
                            <button 
                                className={`btn-pay-now ${!scheme.canPay ? 'disabled' : ''}`} 
                                onClick={() => handleClickPayNow(scheme)}
                                disabled={!scheme.canPay}
                            >Pay Now</button>
                        </div>
                        
                        <div className='upcoming-due-date'>
                        {scheme.noOfPaidInstallments !== 11 && (
                            <>
                            <h3>Upcoming Due Date:</h3>
                            <p>{formatDate(scheme.upcomingDueDate)}</p>
                            </> )} 
                        </div>
                        {scheme.noOfPaidInstallments === 11 && (
                 <div className='new-scheme-alert-after-completion'>
                     You are eligible to purchase the jewels after 1 month.
                          </div>
            )}
                    </div>
                </div>
            ))}

            {paymentData && (
                <RazorpayPayment
                    transactionData={paymentData}
                    onPaymentSuccess={handlePaymentSuccess}
                    onPaymentFailure={handlePaymentFailure}
                />
            )}
        </div>
    );
};

export default SchemeTicket;

