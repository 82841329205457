import React, { useState } from 'react';
import CustManCards from './CustManCards';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import './DeActivate.css';
import DeActProfile from './DeActProfile';

const DeActivate = () => {
  const [showDeActProfile, setShowDeActProfile] = useState(false); 
  const navigate = useNavigate();

  const onClickGoBack = () => {
    navigate('/admin/home/viewing-user-profile');
  };

  const handleProceedClick = (e) => {
    e.preventDefault(); 
    setShowDeActProfile(true); 
  };

  return (
    <div className="whole-mysave-cont">
      <CustManCards />

      {showDeActProfile ? (
        <DeActProfile /> 
      ) : (
        <div className='close-use-out'>
          <div className='close-use-cont'>Deactivate a User Account</div>
          <form>
            <div className='close-form'>
              <label>
                Full Name
                <input type="text" name="username" placeholder="Enter User Name or Mobile Number" />
              </label>

              <label>
                Customer ID
                <input type="text" name="customerId" placeholder="Enter your ID" />
              </label>
            </div>

            <div className='close-form'>
              <label>
                Mobile Number
                <input type="text" name="mobilenumber" placeholder="Enter User Name or Mobile Number" />
              </label>

              <label>
                Email (optional)
                <input type="text" name="customerId" placeholder="Enter your ID" />
              </label>
            </div>

            <button className="close-proceed" onClick={handleProceedClick}>
              Proceed
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default DeActivate;
