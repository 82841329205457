import React from 'react';
import { HashLoader } from 'react-spinners'; 
import './LoadingOverlay.css';

const LoadingOverlay = ({ isLoading }) => {
    return (
        isLoading && (
        <div className="loading-overlay">
            <div className="loading-spinner">
            <HashLoader color="#5A189A"/> 
            </div>
        </div>
        )
    );
};

export default LoadingOverlay;
