import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from './components/authentication/Signin';
import Home from './components/pages/Home';
import Forgotpassword from './components/authentication/Forgotpassword';
import Registernow from './components/authentication/Registernow';
import MySavingScheme from './components/pages/MySavingScheme';
import NewSavingScheme from './components/pages/NewSavingScheme';
import SchemeInformation from './admin-components/pages/SchemeInformation';
import ManageAccount from './components/pages/ManageAccount';
import ChangePassword from './components/pages/ChangePassword';
import ViewDetailsMySaving from './components/pages/ViewDetailsMySaving';
import ForgotPassword2 from './components/pages/ForgotPassword2';
import NewConPass from './components/pages/NewConPass';
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoute';
import AdminHome from './admin-components/pages/AdminHome';
import CustomerMan from './admin-components/pages/CustomerMan';
import NewUserReg from './admin-components/pages/NewUserReg';
import AddNewScheme from './admin-components/pages/AddNewScheme';
import ViewProfile from './admin-components/pages/ViewProfile';
import CloseScheme from './admin-components/pages/CloseScheme';
import DeActivate from './admin-components/pages/DeActivate';
import FilterScheme from './admin-components/pages/FilterScheme';
import AdminSignin from './admin-components/authentication/AdminSignin';
import { useSelector } from 'react-redux';
import SchemeInfoPop from './components/pages/SchemeInfoPop';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setUser } from './Redux/store/authSlice';
import Profile from './components/common/Profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';
import LoadingOverlay from './components/pages/LoadingOverlay';

const App = () => {

  // const role = useSelector((state) => state.auth.role) || process.env.REACT_APP_ROLE; 
  //const dispatch = useDispatch();
  //const role= useSelector((state)=> state.auth.role);
  //useEffect (() => {
  //dispatch(setUser({role:'user'}));
//}, [dispatch]);
const role = useSelector((state) => state.auth.role);
const isLoading = useSelector((state) => state.auth.isLoading);
console.log("Checking of Loading Indicator:",isLoading)
   //console.log("type of role:",role)
 
  return (
    <div className="App">
     {isLoading && <LoadingOverlay   isLoading={isLoading}/>} 
      <Router>
      <Toaster position="top-right" reverseOrder={false} />
        <Routes>     
          {role === 'admin' && (
            <>
              <Route path="/" element={<Signin role="admin" />} />
              <Route path="/signin" element={<Signin role="admin" />} />
              <Route path="/forgotpassword" element={<Forgotpassword />} />
              <Route path="/admin/home" element={<ProtectedRoute><AdminHome /></ProtectedRoute>}>
                <Route path="customer-management" element={<CustomerMan />} />
                <Route path="new-user-reg" element={<NewUserReg />} />
                <Route path="add-new-scheme" element={<AddNewScheme />} />
                <Route path="scheme-information" element={<SchemeInformation />} />
                <Route path="viewing-user-profile" element={<ViewProfile />} />
                <Route path="close-user-scheme" element={<CloseScheme />} />
                <Route path="deactivate-user" element={<DeActivate />} />
                <Route path="filter-scheme" element={<FilterScheme />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="new-conform-password" element={<NewConPass />} />
              </Route>
            </>
          )} 
           {role === 'user' && (
            <>
              <Route path="/" element={<Signin role="user" />} />
              <Route path="/signin" element={<Signin role="user" />} />
              <Route path="/forgotpassword" element={<Forgotpassword />} />
              <Route path="/registernow" element={<Registernow />} />
              <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>}>
                <Route path="my-saving-scheme" element={<ProtectedRoute><MySavingScheme /></ProtectedRoute>} />
                <Route path="new-saving-scheme" element={<NewSavingScheme />} />
                <Route path="scheme-information" element={<SchemeInfoPop />} />
                <Route path="view-detail-scheme" element={<ViewDetailsMySaving />} />
                <Route path="forgot-password2" element={<ForgotPassword2 />} />
                <Route path="manage-account" element={<ManageAccount />} />
               <Route path="/home/profile" element={<Profile/>} /> 
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="new-conform-password" element={<NewConPass />} />
              </Route>
            </>
          )}
          
        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
