import React from 'react'

const FilterScheme = () => {
  return (
    <div>
      This is Filter Scheme Page
    </div>
  )
}

export default FilterScheme
