import React from 'react';
import './MySavingScheme.css';
import Priceupdates from './Priceupdates';
import SchemeTicket from './SchemeTicket';
import Footer from '../common/Footer';


const MySavingScheme = () => {

  return (
    <>
    <div className="whole-mysave-cont">
  <Priceupdates/>
    <SchemeTicket/>
      {/*<Footer/> */}    
        </div>
      
         </>
  );
};

export default MySavingScheme;
