import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Registernow.css';
import WelcomePage from './WelcomePage';
import logo from '../common/asset1/rtm-logo.gif';
import successtick from '../common/asset1/tick-mark.png';
import CopyRightSigin from './CopyRightSigin';
import { useEffect } from 'react';
import LoadingOverlay from '../pages/LoadingOverlay'; 
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Error as ErrorIcon } from '@mui/icons-material';
import Welcont from './Welcont';
import Footer from '../common/Footer';
import warningIcon from '../common/asset1/warning-symbol.svg';
import { Button } from 'bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoading } from '../../Redux/store/authSlice';

const Registernow = ({ role }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
 const [isRegistered,setIsRegistered] = useState(false);
 const [isMobile, setIsMobile] = useState(false);
 //const [isLoading, setIsLoading] = useState(false);
 const [isTablet, setIsTablet] = useState(false);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [passwordValidations, setPasswordValidations] = useState({
  length: false,
  uppercase: false,
  number: false,
  noSpaces: false,
});
const [captchaToken, setCaptchaToken] = useState('');

const [isPasswordFocused, setIsPasswordFocused] = useState(false); 

 useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  window.addEventListener('resize', handleResize);
  handleResize(); 

  return () => window.removeEventListener('resize', handleResize);
}, []);



const handleNavigation = (path) => {
  dispatch(setIsLoading(true));
  setTimeout(() => {
   dispatch(setIsLoading(false));
  },200);
  navigate(path);
};

useEffect(() => {
  const handleResize = () => {
    setIsTablet(window.innerWidth >= 577 && window.innerWidth <= 1133);
  };

  window.addEventListener('resize', handleResize);
  handleResize(); 

  return () => window.removeEventListener('resize', handleResize);
}, []);

const isLoading = useSelector((state) => state.auth.isLoading);
 const apiUrl = process.env.REACT_APP_API_URL;
 const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
 const loginType = role === 'admin' ? 0 : 1;

 useEffect(() => {
  const loadRecaptcha = () => {
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log("reCAPTCHA script loaded.");
      };
      script.onerror = (err) => {
        console.error("Failed to load reCAPTCHA script", err);
      };
      document.body.appendChild(script);
    }
  };

  loadRecaptcha();

  return () => {
    const script = document.querySelector(`script[src="https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}"]`);
    if (script) {
      document.body.removeChild(script);
    }
  };
}, [recaptchaSiteKey]);

const handleCaptcha = async () => {
  console.log(window.grecaptcha); 
  if (window.grecaptcha && window.grecaptcha.ready) {
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(recaptchaSiteKey, { action: 'submit' });
        setCaptchaToken(token);
       // setCaptchaVerified(true);
        console.log("Captcha Token:", token);
      } catch (error) {
        //console.error("Failed to execute reCAPTCHA:", error);
        //setCaptchaVerified(false);
      }
    });
  } else {
    console.error("reCAPTCHA is not loaded properly");
    setCaptchaVerified(false);
  }
};

const clearError = (field) => {
  setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
};

 const handlePasswordFocus = () => {
  setIsPasswordFocused(true);
};
 const handleUsernameChange = (e) => {
  const value = e.target.value;
  if (value.length <= 20) {
    setUsername(value);
    clearError('username');
  }
};

const handleMobileNumberChange = (e) => {
  const value = e.target.value;
  if (/^\d*$/.test(value) && value.length <= 10) {
    setMobileNumber(value);
    clearError('mobileNumber'); 
  }
};


const handleEmailChange = (e) => {
  setEmail(e.target.value);
  clearError('email'); 
};

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

 // const handleCaptchaChange = (value) => {
    //console.log("handleCaptchaChange called");
   // console.log("Captcha value from change:", value);
   // if (value) {
  //    setCaptchaVerified(true);
   //  setCaptchaToken(value);  
   
   // } else {
   //   setCaptchaVerified(false);
   //   setCaptchaToken('');  
  //  }
   
//};
const handleCaptchaChange = (token) => {
  if (token) {
    console.log("Captcha token:", token);
    setCaptchaToken(token);
    setCaptchaVerified(true);
    setErrors((prevErrors) => ({ ...prevErrors, captchaVerified: '' }));
  } else {
    console.error("Captcha verification failed");
    setCaptchaVerified(false);
    setErrors((prevErrors) => ({ ...prevErrors, captchaVerified: 'Captcha verification failed' }));
  }
};

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const validatePasswordRequirements = () => {
      setPasswordValidations({
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        number: /[0-9]/.test(password),
        noSpaces: !/\s/.test(password),
      });
    };
    validatePasswordRequirements();
  }, [password]);
  
  const passwordsMatch = password === confirmPassword;
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateUserName = (username) => {
    const userNameRegex = /^[a-zA-Z0-9._-]+$/;
    const numericOnlyCheck = /^\d+$/;
    return userNameRegex.test(username) && !numericOnlyCheck.test(username);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };
  
  
  const handleSubmit = async (e) => {
    dispatch(setIsLoading(true));
    e.preventDefault();
    await handleCaptcha();
    const newErrors = {};
    setErrors({});
    console.log("Errors:", newErrors); 
  
    if (!validateUserName(username)) {
      newErrors.username = 'Invalid username.';
    }
  
    if (!validateMobileNumber(mobileNumber)) {
      newErrors.mobileNumber = 'Mobile number must contain exactly 10 digits';
    }
  
    if (email && !validateEmail(email)) {
      newErrors.email = 'Invalid email format.';
    }
  
  // if (!validatePassword(password)) {
  //   newErrors.password = 'Password does not meet criteria.';
 //   }
  
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }
  
    if (!termsAccepted) {
      newErrors.termsAccepted = 'Please accept the terms and conditions.';
    }
  
   // if (captchaVerified === false) {
    //  console.log('Captcha was not verified, but proceeding since it is optional.');
   // }
   if (!captchaVerified) {
    newErrors.captchaVerified = 'Captcha is not verified.';
  }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      id:1, 
      userName: username,
      fullName: fullName || '', 
      mobileNumber: mobileNumber,
      password: password,
      confirmPassword: confirmPassword,
      email: email || '',
      captchaToken,
      loginType,
    };
    console.log(payload);
    dispatch(setIsLoading(true));
    try {
      const response = await fetch(`${apiUrl}/account/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setIsRegistered(true); 
      }  else {
       
        const errorData = await response.json();
  
        const newErrors = {};
        if (errorData?.message) {
          if (errorData.message.includes("Username already exists")) {
            newErrors.username = "Username already exists";
          }
          if (errorData.message.includes("Email already exists")) {
            newErrors.email = "Email already exists";
          }
          if (errorData.message.includes("Mobile Number already exists")) { 
            newErrors.mobileNumber = "Mobile Number already exists";
          }
        } else {
          newErrors.general = "Registration failed. Please try again.";
        }
  
        setErrors(newErrors);
        console.error('Failed to submit the form:', errorData);
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setErrors({ general: 'An error occurred. Please try again later.' });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <div className={`sign-in-out ${isMobile ? 'mobile-view' : ''}`}>
        {isLoading && <LoadingOverlay />}
          {!isMobile && <WelcomePage />}
      <div className='reg-container'>
      <div className='reg-con-inner'>
        <div className='reg-form'>
          <img src={logo} alt="Logo" className="sub-logo" /> 
         <Welcont/>
         <h2>New User Registration</h2> 

          {!isRegistered ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group-register">
              <label>User Name<span className="required">*</span></label>
              <input type="text" value={username}  onChange={handleUsernameChange} 
           required />
  
              {errors.username &&    <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.username}</span> </div>}
              {errors.general && <div className="error-with-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.general}</span> </div>}
            </div>
            <div className="form-group-register">
              <label>Full Name</label>
                   <input type="text" value={fullName} onChange={(e) => {
                const input = e.target.value;
                   if (/^[a-zA-Z\s]*$/.test(input)) {
                 setFullName(input);
                    }
                  }} />
              {errors.fullName && <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.fullName}</span> </div>}
            </div>
            <div className="form-group-register">
              <label>Mobile Number<span className="error-register-now">*</span></label>
                          <div className="input-container">
                <span className="country-code">+91 </span>
                <input type="text" value={mobileNumber} onChange={handleMobileNumberChange} required maxLength={10} />
              </div>
              {errors.mobileNumber && <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.mobileNumber}</span> </div>}
            </div>
            <div className="form-group-register">
              <label>Email (optional)</label>
              <input type="email" value={email} onChange={handleEmailChange} />
              {errors.email && <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.email}</span></div>}
              {errors.general && <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.general}</span></div>}
            </div>
            <div className="form-group-register">
              <label>Password</label>
              <div className="password-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={handlePasswordFocus}
                  required
                      />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={toggleShowPassword}
                  className="password-icon"
                                />
              </div>
              {errors.password && <span className="error-register-now">{errors.password}</span>}
            </div>
            <div className="form-group-register">
              <label>Confirm Password</label>
              <div className="password-container">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                              />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  onClick={toggleShowConfirmPassword}
                  className="password-icon"
                />
              </div>
              {isPasswordFocused && (
              <div className="password-validation">
              {confirmPassword.length > 1 && (
          <div style={{ color: passwordsMatch ? '#45A145' : '#E51A1A' }}>
            {passwordsMatch ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#E51A1A' , marginRight: '8px' }} />
            )}
            {passwordsMatch ? "Passwords match" : "Passwords does not match"}
          </div>)}
          <div style={{ color: passwordValidations.length ? '#45A145' : '#808080' }}>
            {passwordValidations.length ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' }} />
            )}
            Password must be at least 8 characters long
          </div>
          <div style={{ color: passwordValidations.uppercase ? '#45A145' : '#808080', display: 'flex', alignItems: 'center'}}>
            {passwordValidations.uppercase ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px', verticalAlign: 'middle' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' , verticalAlign: 'middle'  }} />
            )}
            Password must contain at least one uppercase letter
          </div>
          <div style={{ color: passwordValidations.number ? '#45A145' : '#808080' }}>
            {passwordValidations.number ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must contain at least one number
          </div>
          <div style={{ color: passwordValidations.noSpaces ? '#45A145' : '#808080' }}>
            {passwordValidations.noSpaces ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must not contain spaces
             </div>
               </div>
                   )}
              {errors.confirmPassword && <span className="error-register-now">{errors.confirmPassword}</span>}
            </div>
            <div className='terms-box-out'>
            <div className='terms-box'>
              <input type="checkbox" id="terms" checked={termsAccepted} onChange={handleCheckboxChange} class="custom-checkbox"/>
              <label for="terms" class="custom-checkbox-label"></label>
              <label htmlFor="terms">
                By checking this box, you agree to the <Link to="/privacypolicy" class="policy-link"  onClick={() => handleNavigation('/privacypolicy')}>Privacy Policy</Link> and <Link to="/termsofuse"  
                class="policy-link"  onClick={() => handleNavigation('/termsofuse')}>Terms of Use</Link>
              </label>
            </div>
            </div>
            {errors.termsAccepted && <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
            <span className="error-register-now">{errors.termsAccepted}</span></div>}
            <div className='captcha-box'>
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                onChange={handleCaptchaChange}
                
                onExpired={() => setCaptchaVerified(false)}
              />
                <div className='captcha-outer'>
              {errors.captchaVerified && <div className="error-reg-icon-out">   <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <span className="error-register-now">{errors.captchaVerified}</span></div>} </div>
            </div>
            <button className='btn btn-register' type="submit"  disabled={isLoading}>Register</button>
            <div className='form-group-newuser'>
          <label>Already a User?</label>
          <Link to="/signin" className='register-name' onClick={() => handleNavigation('/signin')}>Login Now</Link>
          </div>
          </form>  ) : (
            
            <div className='success-message-teeny-cont'>
             <div className="success-icon-wrapper">
              <img src={successtick} alt="success-mark" className="success-tick-mark" />
                    </div>
              Account Created Successfully <br></br>
            
              <button className='register-login-btn'>   <Link to="/signin" className='register-name-btn' onClick={() => handleNavigation('/signin')}> Login Now </Link></button>
               </div>
           
   
         )}
          
          {isMobile &&  !isTablet && <Footer/>}
        </div>
        {isTablet &&  <Footer/> }
        {!isMobile &&  !isTablet &&
        
        <CopyRightSigin/>  }
        </div>
        
            </div>
           
            
         </div>
    
  );
};

export default Registernow;
