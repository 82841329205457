import React from 'react'
import { useState, useEffect } from 'react';

const CopyRightSigin = () => {
  const [isMobile, setIsMobile] = useState(false);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  return (
    <div className='rtm-copyright-overall'>
    <div className='rtm-copy-mark-out'>
    <div className="rtm-copy-mark">
        © {currentYear} Ram Thanganagai Maligai {!isMobile && ( <>| <span className="rtm-policy" style={{ color: "#5A189A",marginLeft:"0.15rem",marginRight:"0.1rem" }}>
          Privacy Policy  </span> & {' '}
        <span className="rtm-terms"  style={{ color: "#5A189A",marginLeft:"0.071rem" }}>Terms and Conditions</span> </>)}
      </div>
      </div>
      <span className="rtm-tech-container">
      <div className='rtm-powered-by'>Powered by</div>
      <div className='rtm-thofa'>
        <span className="rtm-thofa-text">THOFA</span>
        <span className="rtm-technologies-text"> TECHNOLOGIES</span>
      </div>
    </span>
    </div>
  )
}

export default CopyRightSigin
