import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';


const DeActStaff = () => {
    const navigate=useNavigate();
  
    const onClickGoBack = () => {
      navigate('/admin/home/viewing-user-profile');
    };
  return (
    <>
      <div className='close-use-out'>
  <div className='close-form-go-back'>
 <button className="get-back-out" onClick={onClickGoBack}>
          <Icon icon="iconamoon:arrow-left-2-bold" className="go-back-symbol" />
          Go Back
        </button>  </div>
        
        <div className='close-form-staff'>
          <label>
            Handling for Closure
            <input type="text" name="username" placeholder="Handling for Closure" />
          </label>
          
          <label>
            Reason for Closure
            <input type="text" name="customerId" placeholder="Reason for Closure" />
          </label>
        </div>

   

        <button className="close-proceed">Close Scheme</button>
     
 </div>
    </>
  )
}

export default DeActStaff
