import React from 'react';
import { useState, useEffect } from 'react';
import CopyRightSigin from './CopyRightSigin';
import WelcomePage from './WelcomePage';
import ForgotAuthForm from './ForgotAuthForm';
import ForgotPassMob from './ForgotPassMob';
   
   
const Forgotpassword = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); 
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 577 && window.innerWidth <= 1133);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
    {!isMobile && (
    <div className='sign-in-out'>
          {!isMobile && !isTablet && <WelcomePage />}
       
      <div className='auth-container-forgot-password'>
        <ForgotAuthForm/>
        {!isMobile &&   !isTablet &&
        
        <CopyRightSigin/>  }
      </div>
    </div>)} 
    {isMobile &&  <ForgotPassMob/>   }

    </>
  );
};

export default Forgotpassword;
