import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import CloseStaff from './CloseStaff';
import CustManCards from './CustManCards';

const CloseMemSch = () => {
  const [showCloseStaff, setShowCloseStaff] = useState(false); 
  const [showPopup, setShowPopup] = useState(false); 
  const navigate = useNavigate();

  const viewdetailhandleClick = () => {
    navigate('/home/view-detail-scheme');
  };

  const onClickGoBack = () => {
    navigate('/admin/home/viewing-user-profile');
  };

  const handleCloseSchemeClick = () => {
    setShowPopup(true); 
  };

  const handleProceedClick = () => {
    setShowPopup(false);
    setShowCloseStaff(true); 
  };

  const handleCancelClick = () => {
    setShowPopup(false); 
  };

  return (
    <>
      {showCloseStaff ? (
        <CloseStaff /> 
      ) : (
        <div className="whole-mysave-cont">
        <CustManCards />
        <div className='close-use-out'>
          <button className="get-back-out" onClick={onClickGoBack}>
            <Icon icon="iconamoon:arrow-left-2-bold" className="go-back-symbol" />
            Go Back
          </button>
          <div className="member-scheme">
            <div className="maturity-cont">
              <div className='due-cont'>
                <div className='due-filled'>
                  <span>08</span>
                  <span>11</span>
                </div>
                <div className='mat-date-cont'>
                  <h3>Maturity Date</h3>
                  <h2>15 / 06 / 2024</h2>
                </div>
              </div>
              <div className="scheme-container">
                <div className="scheme-detail">
                  <span className="label">Scheme Name</span>
                  <span className="value">BAB - 500</span>
                </div>
                <div className="scheme-detail">
                  <span className="label">Amount</span>
                  <span className="value">₹ 500 /-</span>
                </div>
                <div className="scheme-detail">
                  <span className="label">Membership No.</span>
                  <span className="value">B031</span>
                </div>
                <div className="scheme-detail">
                  <span className="label">Group Code:</span>
                  <span className="value">G2</span>
                </div>
              </div>
              <div className='pay-detail'>
                <h3>Payment Status:</h3>
                <p>2 Months Pending</p>
              </div>
            </div>

            <div className="id-cont">
              <div className='profile-cont'>
                <div className='profile-out-cont'>
                  <FontAwesomeIcon icon={faUser} className='profile-icon' />
                </div>
                <div className='profile-det'>
                  <p>Avinash M</p>
                  <p>+91 89469  98462</p>
                  <p>Male</p>
                </div>
              </div>
              <div className='btn-group-pay'>
                <button className='btn-view-details' onClick={viewdetailhandleClick}>View Details</button>
                <button className='btn-pay-now'>Pay Now</button>
              </div>
              <div className='upcoming-due-date'>
                <h3>Upcoming Due Date:</h3>
                <p>12 / 08 / 2024</p>
              </div>
            </div>
          </div>

          <div className='btn-close-grp'>
            <button className="close-btn view-det">View Details</button>
            <button className="close-btn close-sch" onClick={handleCloseSchemeClick}>Close Scheme</button>
          </div>
        </div>

          {showPopup && (
            <div className='popup-overlay'> 
            <div className="popup-mem-sch">
              <div className="popup-content-mem-sch">
                <p>Looks like the scheme still has 2 months left to end. Do you want to proceed with foreclosure?</p>
                <div className="popup-buttons-mem-sch">
                  <button className="popup-btn-mem-sch proceed-btn-mem-sch" onClick={handleProceedClick}>Proceed</button>
                  <button className="popup-btn-mem-sch cancel-btn-mem-sch" onClick={handleCancelClick}>Cancel</button>
                </div>
              </div>
            </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CloseMemSch;
