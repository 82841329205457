import React from 'react'
import CustManCards from './CustManCards'
import SchemeTicket from '../../components/pages/SchemeTicket';
import AdminSchemeTicket from './AdminSchemeTicket'
import MemberCons from '../../components/pages/MemberCons';

const AddNewScheme = () => {
  return (
    
  <div className="whole-mysave-cont">
    <CustManCards/> 
    <AdminSchemeTicket/>
    
    </div>
  )
}

export default AddNewScheme

