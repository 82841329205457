import React, { useState, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import akshayabanner from './asset1/akshaya.png';
import callInfoIcon from './asset1/call-info-icon.svg';
import telephoneIcon from './asset1/caller-symbol.svg';
import telephoneIconblack from './asset1/call-info-icon-black.svg';
import mailIcon from './asset1/mail-symbol.svg';
import mailIconblack from './asset1/mail-symbol-black.svg';
import locationIcon from './asset1/location-symbol.svg';
import locationIconblack from './asset1/location-symbol-black.svg';
import callInfoClose from './asset1/call-info-close-icon.svg';
import mobHomeGray from './asset1/mob-home-gray.svg';
import mobHomeViolet from './asset1/mob-home-violet.svg';
import mobProfileGray from './asset1/mob-prof-gray.svg';
import mobProfileViolet from './asset1/mob-profile-violet.svg';
import mobNewSavingScheme from './asset1/mob-new-saving-scheme.svg';
import { useLocation } from 'react-router-dom';

const Sidebar = ({ sidebarOpen, toggleSidebar , isMobile, Profile}) => {
  const navigate = useNavigate(); 
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [activePage, setActivePage] = useState('/home/my-saving-scheme'); 
  const location = useLocation();
  //const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsFullscreen(searchParams.get('fullscreen') === 'true');
  }, [location]);
  useEffect(() => {
    if (isFullscreen) {
      navigate('/home/my-saving-scheme?fullscreen=true');
    }
  }, [isFullscreen, navigate]);
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const toggleContactInfo = () => {
    setIsOpenContact(!isOpenContact);
  };

  const userLink = [
    { to: '/home/my-saving-scheme', name: 'My Savings Schemes' },
    { to: '/home/new-saving-scheme', name: 'New Saving Scheme' },
    { to: '/home/scheme-information', name: 'Scheme Information' },
    ...(isMobile ? [{ to: '/home/profile', name: 'Profile' }] : []),
  ];


  const handleSchemeInformationClick = () => {
    toggleFullscreen(); 
  };

  const handleCloseIconClick = () => {
    setIsFullscreen(false); 
    navigate('/home/my-saving-scheme'); 
  };

    const handleLinkClick = (path) => {
      setActivePage(path);
      if (isMobile) {
        toggleSidebar(); 
      }
      navigate(path);
    };
 
  return (
   
    <>
     {!isMobile && (
               <div className={`whole-sidebar ${sidebarOpen ? 'open' : ''}`}>
      <div className='content-sidebar'>
        <h2>My Account</h2>
        <p>SAVING SCHEMES</p>
        <ul>
          {userLink.map((link, index) => (
            <li key={index}>
           <Link
           to={link.to}
            onClick={() => {
             if (link.name === 'Scheme Information') {
              handleSchemeInformationClick(); 
                 }
             handleLinkClick(link.to); 
            }}
         >
          {link.name}
           </Link>
            </li>
          ))}
        </ul>

        <img
          src={akshayabanner}
          alt="Akshaya-Banner"
          className="akshaya-banner"
          onClick={toggleFullscreen}
        />

        {isFullscreen && (
          <div className="fullscreen-overlay">
            <div className="fullscreen-content">
              <img
                src={akshayabanner}
                alt="Akshaya-Banner"
                className="akshaya-banner-fullscreen"
              />
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={handleCloseIconClick}
              />
            </div>
          </div>
        )}

        <div className='call-info-cont' onClick={toggleContactInfo}>
          <img 
            src={isOpenContact ? callInfoClose : callInfoIcon} 
            alt={isOpenContact ? "close-icon" : "call-info-icon"} 
            className='call-info-icon'
          />

{isOpenContact && (
            <div className="contact-icons">
              <div className="contact-item">
                <img
                  src={hoveredIcon === 'telephone' ? telephoneIconblack : telephoneIcon}
                  alt="telephone-icon"
                  className='child-icon-call-info'
                  onMouseEnter={() => setHoveredIcon('telephone')}
                  onMouseLeave={() => setHoveredIcon(null)}
                />
                <label className="contact-label">0413 226 0300</label>
              </div>
              <div className="contact-item">
                <img
                  src={hoveredIcon === 'mail' ? mailIconblack : mailIcon}
                  alt="mail-icon"
                  className='child-icon-call-info'
                  onMouseEnter={() => setHoveredIcon('mail')}
                  onMouseLeave={() => setHoveredIcon(null)}
                />
                <label className="contact-label">contact@ramthanganagaimaligai.com</label>
              </div>
              <div className="contact-item">
                <img
                  src={hoveredIcon === 'location' ? locationIconblack : locationIcon}
                  alt="location-icon"
                  className='child-icon-call-info'
                  onMouseEnter={() => setHoveredIcon('location')}
                  onMouseLeave={() => setHoveredIcon(null)}
                />
                <label className="contact-label">225, JN Street, Puducherry - 605 001</label>
              </div>
            </div>
          )}
           {!isMobile && !isOpenContact && (
           <div className='call-info-para'> 
           <p>Need any</p>
           <h3>Help?</h3>
            </div>
               )}
           {isMobile && !isOpenContact && (
           <div className='call-info-para'> 
           <p>Need any</p>
           <h3>Help?</h3>
            </div>
               )}
        </div>
      </div>
    </div> )}
    {isMobile && (
  <div className='mob-sidebar-out'>
    
   
    <div className={`mob-sidebar-cont ${activePage === '/home/my-saving-scheme' ? 'active' : ''}`}>
      <img
        src={activePage === '/home/my-saving-scheme' ? mobHomeViolet : mobHomeGray}
        alt="Home"
        className="mob-sidebar-icon"
        onClick={() => handleLinkClick('/home/my-saving-scheme')}
      />
      <p>Home</p>
    </div>

   
    <div className='mob-sidebar-cont-new-saving'>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="0"
    height="0"
    viewBox="0 0 100 100" 
  >
  
  </svg>
      <img
        src={mobNewSavingScheme}
        alt="New-Saving-Scheme"
        className="mob-sidebar-icon-new-saving"
        onClick={() => handleLinkClick('/home/new-saving-scheme')}    style={{ width: '90px', height: '90px'} }
      />
      <p>New Scheme</p>
    
    </div>


    
    <div className={`mob-sidebar-cont ${activePage === '/home/profile' ? 'active' : ''}`}>
      <img
        src={activePage === '/home/profile' ? mobProfileViolet : mobProfileGray}
        alt="Profile-menu"
        className="mob-sidebar-icon"
        onClick={() => handleLinkClick('/home/profile')}
      />
      <p>Profile</p>
    </div>

  </div>
)}


    </>
  );
};

export default Sidebar;
