import React, { useState } from 'react';
import Priceupdates from './Priceupdates';
import { useNavigate } from 'react-router-dom';
import './NewConPass.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@iconify/react';
const NewConPass = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword,setShowNewPassword]=useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();


  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8 && password.length <= 20;
    const upperCaseCheck = /[A-Z]/.test(password);
    const lowerCaseCheck = /[a-z]/.test(password);
    const digitCheck = /[0-9]/.test(password);
    const specialCharCheck = /[!@#$%^&*]/.test(password);

    if (!lengthCheck) return 'Password must be 8-20 characters long.';
    if (!upperCaseCheck) return 'Password must contain at least one uppercase letter.';
    if (!lowerCaseCheck) return 'Password must contain at least one lowercase letter.';
    if (!digitCheck) return 'Password must contain at least one number.';
    if (!specialCharCheck) return 'Password must contain at least one special character.';
    return null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    const newPasswordError = validatePassword(newPassword);
    if (newPasswordError) validationErrors.newPassword = newPasswordError;

    if (newPassword !== confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match.';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
     
      console.log("Password changed successfully");
    }
  };

  const onClickGoBack = () => {
    navigate('/home/change-password');
  };

  const toggleShowPassword = (setShowPassword) => {
    setShowPassword(prevState => !prevState);
  };
  return (
    <div className='man-acc-out'>
      <Priceupdates />
      <div className='man-acc-cont'>
      <button className='go-back-cont' onClick={onClickGoBack}> <Icon icon="iconamoon:arrow-left-2-bold" className='go-back-arrow'/>Go Back</button>
        <div className="change-pass-auth-form">
        
          <h3>Change Password</h3>
          <form onSubmit={handleSubmit}>
            <div className="change-pass-form-group">
              <label>New Password:</label>
              <div className="password-input-container">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showNewPassword ? faEyeSlash : faEye}
                  onClick={() => toggleShowPassword(setShowNewPassword)}
                  className="password-icon"
                />
              </div>
              {errors.newPassword && (
                <p className="error-new-con">{errors.newPassword}</p>
              )}
            </div>
            <div className="change-pass-form-group">
              <label>Confirm Password:</label>
              <div className="password-input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  onClick={() => toggleShowPassword(setShowConfirmPassword)}
                  className="password-icon"
                />
              </div>
              {errors.confirmPassword && (
                <p className="error-new-con">{errors.confirmPassword}</p>
              )}
            </div>
            <div className="change-pass-grp-btn-new-con">
              <button type="submit" className="btn-btn-change-new-con">Change password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewConPass;
