import React from 'react'
import logo from '../common/asset1/rtm-logo.gif';
//import { useState, useEffect } from 'react';
const MobLogo = () => {
   // const [isMobile, setIsMobile] = useState(false);

   // useEffect(() => {
       // const handleResize = () => {
        //  setIsMobile(window.innerWidth <=576);
        //};
    //
      //  window.addEventListener('resize', handleResize);
      //  handleResize(); 
    
      //  return () => window.removeEventListener('resize', handleResize);
     // }, []);
     
  return (
    <>
    <img src={logo} alt="Logo" className="sub-logo" /> 
    </>
  )
}

export default MobLogo
