
import { useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../Redux/store/authSlice";
import { setIsLoading } from '../../Redux/store/authSlice';
import { useSelector} from 'react-redux';
const SessionTimeout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timeoutDuration = 30 * 60 * 1000;
  const timerRef = useRef(null);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const handleLogout = useCallback(() => {
    dispatch(setIsLoading(true))
    setTimeout(() => {
      dispatch(clearUser());
      localStorage.clear();
      navigate("/signin");

      dispatch(setIsLoading(false));
    }, 3000);
  }, [dispatch, navigate]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      handleLogout();
    }, timeoutDuration);
  }, [handleLogout, timeoutDuration]);

  useEffect(() => {
    const activityEvents = ["click", "mousemove", "keydown", "scroll", "touchstart"];

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    resetTimer();

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
      clearTimeout(timerRef.current);
    };
  }, [resetTimer]);

  return null;
};

export default SessionTimeout;
