import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react'; 
import CustManCards from './CustManCards';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import TuneIcon from '@mui/icons-material/Tune';
import InstallmentsCont from '../../components/pages/InstallmentsCont';

const ViewProfile = () => {
    const [isSpinning, setIsSpinning] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setIsSpinning(true);
        setTimeout(() => {
            setIsSpinning(false);
        }, 1000);
    };

    

  const onClickGoBack = () => {
    navigate('/admin/home/add-new-scheme');
  };
    return (
        <div className="whole-mysave-cont">
            <CustManCards />

            <div className='search-filter-out'>
                <div className='search-cont'>
                    <h4>
                        <SearchIcon className='mat-icon' />
                        Search...
                    </h4>
                    <p>You can search by scheme, scheme no...</p>
                </div>
                <div className='sync-filt-out'>
                    <div className='sync-cont' onClick={handleClick}>
                        <SyncIcon className={`mat-icon ${isSpinning ? 'spin' : ''}`} />
                        Refresh
                    </div>
                    <div className='filter-cont'>
                        <TuneIcon className='mat-icon' />
                        Filter
                    </div>
                </div>
            </div>
            <div className='go-back-out'>
        <button className='go-back-cont' onClick={onClickGoBack}> <Icon icon="iconamoon:arrow-left-2-bold" className='go-back-arrow'/>Go Back</button>
       
        <div className='scheme-out-cont-2'>
        <div className='my-saving-scheme-details'>My Saving Scheme Details</div>
          <div className='scheme-container-2'>
            <div className='scheme-detail-2'>
              <span className='label'>Scheme Name</span>
              <span className='value2'>BAB - 500</span>
            </div>
            <div className='scheme-detail-2'>
              <span className='label'>Amount</span>
              <span className='value2'>₹ 500 /-</span>
            </div>
            <div className='scheme-detail-2'>
              <span className='label'>Membership No.</span>
              <span className='value2'>B031</span>
            </div>
            <div className='scheme-detail-2'>
              <span className='label'>Group Code:</span>
              <span className='value2'>G2</span>
            </div>
          </div>

          <div className='mob-name-out'>
            <div className='mob-nam-cont'><h3>Full Name</h3> <p>Avinash M</p></div>
            <div className='mob-nam-cont'><h3>Mobile Number</h3><p>+91 89469 98462</p></div>
            <div className='mob-nam-cont'><h3>Email</h3><p>-</p></div>
            <div className='mob-nam-cont'><h3>Payment Status</h3><p>2 Months Pending</p></div>
          </div>

          <div className='mat-upc-out'>
            <div className='mat-dat-cont'><h3>Maturity Date</h3> <p>12 / 08 / 2024</p></div>
            <div className='mat-dat-cont'><h3>Upcoming Due Date:</h3> <p>12 / 08 / 2024</p></div>
          </div>
        </div>
      </div>
      <div className='inst-out-cont'>
        <InstallmentsCont/>
      </div>
            
        </div>
    );
};

export default ViewProfile;
