
import React, { useState, useEffect } from 'react';
import './TransactDet.css';
import { useSelector } from 'react-redux';
import LoadingOverlay from './LoadingOverlay';
const PaymentMethodEnum = {
  0: 'None',
  1: 'InternetBanking',
  2: 'UPI',
  3: 'Card',
  4: 'Wallet',
  5: 'CreditCard',
  6: 'DebitCard',
  7: 'BankTransfer',
  8: 'OnlinePayment',
  9: 'Cash',
  10: 'Check',
};

const PaymentStatusEnum = {
  0: 'Created',
  1: 'Authorized',
  2: 'Captured',
  3: 'Refunded',
  4: 'Failed',
  5: 'Skipped',
};
const TransactDet = ({ paymentTransactionId }) => {
  const [data, setData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(true);
  const userToken=useSelector(state => state.auth.token);
 // console.log('transaction Token Token from Redux:', userToken)
   useEffect(() => {
    const fetchTransactionDetails = async () => {
      //console.log('Payment Transaction ID Transact Det from transaction Pop up:', paymentTransactionId);
      try {
        const response = await fetch(`${apiUrl}/transaction/details/${paymentTransactionId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        setData(jsonData[0]); 
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false); 
      }
    };
    if (paymentTransactionId) { 
      fetchTransactionDetails();
    }
  }, [paymentTransactionId, userToken]);

  if (isLoading) {
    return <LoadingOverlay />; 
  }


  if (!data) {
    return <LoadingOverlay/>; 
  }
  return (
    <div className='transact-out'>
      <div className='transact-det-cont'>
        Transaction Details
      </div>
      <form className='transact-form-container'>
        <div className='transact-form-item'>
          <span>Scheme Name</span>
          <span>{data.schemeName}</span>
        </div>
        <div className='transact-form-item'>
          <span>Scheme No.</span>
          <span>{data.schemeId}</span>
        </div>
        <div className='transact-form-item'>
          <span>Date Paid</span>
          <span>{new Date(data.datePaid).toLocaleDateString('en-GB',{
            year:'numeric',
            month:'2-digit',
            day:'2-digit'
          })}</span>
        </div>
        <div className='transact-form-item'>
          <span>Amount Paid</span>
          <span>₹ {data.amount}/-</span>
        </div>
        {/* <div className='transact-form-item'>
          <span>No. of Installments</span>
          <span>{data.numberOfInstallments}</span>
        </div>  
        <div className='transact-form-item'>
          <span>Total Amount</span>
          <span>₹ {data.totalAmount}/-</span>
        </div> */}
        <div className='transact-form-item'>
          <span>Mode of Payment</span>
          <span>{PaymentMethodEnum[data.paymentMethod]}</span>
        </div>
        <div className='transact-form-item'>
          <span>Transaction ID</span>
          <span>{data.transactionId}</span>
        </div>
        <div className='transact-form-item'>
          <span>Status</span>
          <span style={{color:'#45A145'}}>{PaymentStatusEnum[data.status]}</span>
        </div>
      </form>
    </div>
  );
};

export default TransactDet;