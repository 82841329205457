

import { combineReducers, configureStore } from '@reduxjs/toolkit';
//import rootReducer from '../reducers';
//import { thunk } from 'redux-thunk';
//import transactionReducer from './transactionSlice';
import { authSlice } from './authSlice';
const rootReducers = combineReducers({
  auth:authSlice.reducer,
 // transaction: transactionReducer,
}) 
const store = configureStore({
  //transaction: transactionReducer,
  reducer: rootReducers,
  
 // middleware: (getDefaultMiddleware) => 
   // getDefaultMiddleware().concat(thunk), 
  //devTools: process.env.NODE_ENV !== 'production', 
});

export default store;
