import React, { useState } from 'react';
import CustManCards from './CustManCards';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './NewUserReg.css';

const NewUserReg = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const onClickGoBack = () => {
    navigate('/admin/home/customer-management');
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
   
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  
  };

  return (
    <div className="whole-mysave-cont">
      <CustManCards />
      <div className="create-user-out">
        <button className="get-back-out" onClick={onClickGoBack}>
          <Icon icon="iconamoon:arrow-left-2-bold" className="go-back-symbol" />
          Go Back
        </button>
  <h3>Create a New User Account</h3>
  <div className='new-user-cont'>
        <div className="form-group">
        <label>User Name<span className="required">*</span></label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        {errors.username && <span className="error">{errors.username}</span>}
      </div>

      <div className="form-group">
        <label>Full Name</label>
        <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
        {errors.fullName && <span className="error">{errors.fullName}</span>}
      </div>
      </div>
      <div className='new-user-cont'>
      <div className="form-group">
            <label>Mobile Number<span className="required">*</span></label>
            <div className="input-container">
              <span className="country-code">+91 </span>
              <input
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
              />
            </div>
            {errors.mobileNumber && <span className="error">{errors.mobileNumber}</span>}
          </div>
      <div className="form-group">
        <label>Email (optional)</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {errors.email && <span className="error">{errors.email}</span>}
      </div>
      </div>
      <div className='new-user-cont'>
      <div className="form-group">
        <label>Password</label>
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={toggleShowPassword}
            className="password-icon"
          />
        </div>
        {errors.password && <span className="error">{errors.password}</span>}
      </div>

      <div className="form-group">
        <label>Confirm Password</label>
        <div className="password-container">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
          <FontAwesomeIcon
            icon={showConfirmPassword ? faEyeSlash : faEye}
            onClick={toggleShowConfirmPassword}
            className="password-icon"
          />
        </div>
        {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
      </div>
      </div>
      <button className='btn-create-account'>Create Account</button>
   </div>
   
    </div>
  );
}

export default NewUserReg;
