import React from 'react';
import './FilterCustMan.css';

const FilterCustMan = ({ onClose }) => {
    return (
        <div className="filter-popup-overlay">
            <div className="filter-popup">
                <h3>Filter By</h3>
     <div className='filter-form'>
                <div className="filter-section">
                    <h4>By Scheme</h4>
                    <select>
                        <option value="">Select Scheme</option>
                        <option value="scheme1">Scheme 1</option>
                        <option value="scheme2">Scheme 2</option>
                    </select>
                </div>

                <div className="filter-section">
                    <h4>By Status</h4>
                    <select>
                        <option value="">Select Status</option>
                        <option value="paid">Paid</option>
                        <option value="not-paid">Not Paid</option>
                    </select>
                </div>
             </div>

             <div className='filter-form'>
                <div className="filter-section">
                    <h4>From Date</h4>
                    <input type="date" defaultValue="2024-05-29" />
                </div>

                <div className="filter-section">
                    <h4>To Date</h4>
                    <input type="date" defaultValue="2024-07-29" />
                </div>
                </div>
                <div className="filter-buttons">
                    <button className="filter-button">Filter</button>
                    <button className="cancel-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default FilterCustMan;