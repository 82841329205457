

//import { createSlice } from '@reduxjs/toolkit';

//const persistedRole = localStorage.getItem('role');
//const persistedToken = localStorage.getItem('token');
//const initialState = {
  //isAuthenticated: false,
 // isAuthenticated: !!persistedToken,
 // user: null,
 // role: persistedRole || null,
 // token: localStorage.getItem('token') || null,  
 //token: persistedToken || null
//};

//export const authSlice = createSlice({
  //name: 'auth',
  //initialState:{
   // isAuthenticated:false,
   // user:null,
    //role:null,
    //token:null,
 // },
//  reducers: {
    //setUser(state, action) {
    
    //  console.log('Action-payload:', action.payload); //action not working      
    
   //   state.isAuthenticated = action.payload.isAuthenticated;
   //   state.user= action.payload.user;
  //    state.role=action.payload.role;
  //    state.token=action.payload.token;
  //   
   //   console.log('After setUser state:', state);
     
  //  },
   // logout(state) {
   //   state.isAuthenticated = false;
   //   state.user = null;
   //   state.role = null;
    //  state.token = null;
    //  localStorage.removeItem('role'); 
   //   localStorage.removeItem('token');
    //  console.log('Token stored in localStorage:', localStorage.getItem('token'));
    //},
 // },
//});


//export const { setUser } = authSlice.actions;
//export default authSlice.reducer;
import {createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  isLoading:false,
  user: Cookies.get('userName') || null,
  token: Cookies.get('token') || null,
  role: process.env.REACT_APP_ROLE || 'user',
  CustomerID: Cookies.get('userID') || null,
  ipAddress: Cookies.get('ipAddress') || null,
  fullName: Cookies.get('fullName') || null,
};

export const authSlice=createSlice({
 
name:"rtm-chitfund-organization",
initialState,
reducers:{
     setUser:(state,action)=>{
      
       const { userID, userName, token, role,ipAddress,fullName } = action.payload;  
       state.CustomerID = userID;
       state.user = userName;
       state.token = token;
       state.role = role || state.role; 
       state.ipAddress = ipAddress;
       state.fullName = fullName; 
     //  Cookies.set('accessToken', token, { secure: true, httpOnly: true });
     Cookies.set('userID', userID);
     Cookies.set('userName', userName);
     Cookies.set('token', token);
     Cookies.set('role', role);
     Cookies.set('ipAddress', ipAddress);
     Cookies.set('fullName',fullName)
    },
   setIsLoading:(state,action) => {
    state.isLoading =action.payload;
   },
    setPaymentSerialID: (state, action) => {
      state.paymentserialID = action.payload; 
    },
    clearUser: (state) => {
        state.user = null;  
        state.token = null; 
        state.CustomerID=null;
        state.paymentserialID = null; 
        state.ipAddress = null;
        state.fullName = null
       // Cookies.remove('accessToken');
       // Cookies.remove('token');
       Cookies.remove('userID');
       Cookies.remove('userName');
       Cookies.remove('token');
       Cookies.remove('ipAddress');
       Cookies.remove('fullName');
      },

      logout: (state) => {
        state.isAuthenticated = false; 
        state.user = null;
        state.token = null;
        state.CustomerID=null;
        state.paymentserialID = null; 
        state.ipAddress = null;
        Cookies.remove('accessToken');
        Cookies.remove('token');
        Cookies.remove('userID');
        Cookies.remove('userName');
        Cookies.remove('role');
        Cookies.remove('ipAddress');
        Cookies.remove('fullName')
      },
},
 
});
 
export const {setUser,setIsLoading, setSavingSchemePlans,setPaymentSerialID,clearUser, logout} =authSlice.actions;
export default authSlice.reducer;