import React, { useState, useEffect } from 'react';
import './Profile.css';
import { faUserCircle, faCog, faKey, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, logout } from '../../Redux/store/authSlice';
import telephoneIconblack from './asset1/call-info-icon-black.svg';
import mailIconblack from './asset1/mail-symbol-black.svg';
import locationIconblack from './asset1/location-symbol-black.svg';
import schemeInfo from './asset1/scheme-info-icon.svg';
import akshayabanner from './asset1/akshaya.png';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SessionTimeout from '../authentication/SessionTimeout';
import { setIsLoading} from '../../Redux/store/authSlice';
import { toast } from "react-hot-toast"
const Profile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false); 
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading); 
  const [isSchemeInfoOpen, setIsSchemeInfoOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1441);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 577 && window.innerWidth <= 1133);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  
  const handleSignOut = () => {
    dispatch(setIsLoading(true));
    setTimeout(() => {
      dispatch(clearUser());
      localStorage.clear();
      sessionStorage.clear();
      dispatch(logout());
      toast.success('Logout successful!');
      dispatch(setIsLoading(false));
      navigate('/signin', { replace: true });
    }, 500); 
  };


  const handleNavigation = (path) => {
    dispatch(setIsLoading(true));
    setTimeout(() => {
     dispatch(setIsLoading(false));
    },200);
    navigate(path);
  };
  const handleCloseIconClick = () => {
    setIsFullscreen(false); 
    navigate('/home/profile'); 
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
   !isDesktop && (isTablet || isMobile) && (
      <div className='whole-profile-out'>
        <SessionTimeout/>
        <div className="profile">
          <h3><span style={{ color: '#171717'}}>Welcome,</span><span style={{ color: '#5A189A'}}>{user}</span></h3>
          <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
        </div>
        <div className="profile-menu-mobile">
          <div className="menu-item" onClick={() => handleNavigation('/home/manage-account')}>
            <span>Manage Account</span>
            <FontAwesomeIcon icon={faCog} className="menu-icon" />
          </div>
          <hr />
          <div className="menu-item"onClick={() => handleNavigation('/home/change-password')}>
            <span>Change Password</span>
            <FontAwesomeIcon icon={faKey} className="menu-icon" />
          </div>
          <hr />
          <div className="menu-item" onClick={toggleFullscreen}> 
            <span>Scheme Information</span>
            <img src={schemeInfo} alt="Scheme Information" className="scheme-info-icon-mobile" />
          </div>
          <hr />
          <div className="menu-item" onClick={handleSignOut}>
            <span style={{ color: '#E51A1A' }}>Sign Out</span>
            <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" style={{ color: '#E51A1A' }} />
          </div>
        </div>

       
        {isFullscreen && (
          <div className="fullscreen-overlay">
            <div className="fullscreen-content">
              <img src={akshayabanner} alt="Akshaya-Banner" className="akshaya-banner-fullscreen-mobile" />
              {!isMobile && (
  <FontAwesomeIcon
    icon={faTimes}
    className="close-icon"
    onClick={toggleFullscreen}
  />
)}

               <FontAwesomeIcon
                icon={faTimes}
                className="close-icon-mobile-view"
                onClick={handleCloseIconClick}
              />
            </div>
          </div>
        )}

        <div className='call-info-para-mobile-view'>
          <div className='need-help'>
            <p>Need any Help?</p>
          </div>
          <div className="contact-item-mobile-view">
            <img 
            src={telephoneIconblack} 
            alt="telephone-icon" 
            className='child-icon-call-info-mob-view' />
            <label className="contact-label-mobile-view">0413 226 0300</label>
          </div>
          <div className="contact-item-mobile-view">
            <img 
            src={mailIconblack}
             alt="mail-icon"
              className='child-icon-call-info-mob-view' />
            <label className="contact-label-mobile-view contact-rtm">contact@ramthanganagaimaligai.com</label>
          </div>
          <div className="contact-item-mobile-view">
            <img src={locationIconblack} alt="location-icon" className='child-icon-call-info-mob-view' />
            <label className="contact-label-mobile-view">225, JN Street, Puducherry - 605 001</label>
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;
