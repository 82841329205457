import React ,{useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import TuneIcon from '@mui/icons-material/Tune';
import { Checkbox, IconButton } from '@mui/material';
import { Edit, MoreVert } from '@mui/icons-material'; 
import 'font-awesome/css/font-awesome.min.css';
import { Icon } from '@iconify/react'; 
import './CustomerManData.css';
import FilterCustMan from './FilterCustMan'
const CustomerManData = () => {
    const [isSpinning,setIsSpinning]=useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const handleClick = () => {
        setIsSpinning(true);
        setTimeout (() => {
            setIsSpinning(false);
    
        },1000)
      }

      const handleCheckboxChange = (id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };
    const handleFilterClick = () => {
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

   
    const customers = [
        { id: '1', name: 'Avinash M', customerId: 'B069', date: '24 / 05 / 2024', scheme: 'BAF-5000', status: 'Paid' },
        { id: '2', name: 'SenthiNathan', customerId: 'B052', date: '24 / 05 / 2024', scheme: 'BAF-10000', status: 'Not Paid' },
        { id: '3', name: 'Karthikeyan S', customerId: 'B039', date: '24 / 05 / 2024', scheme: 'CAA-20000', status: 'Paid' },
        { id: '4', name: 'Rajiv G M', customerId: 'B048', date: '24 / 05 / 2024', scheme: 'CAE-100000', status: 'Paid' },
        { id: '5', name: 'SenthiNathan R', customerId: 'B052', date: '24 / 05 / 2024', scheme: 'BAF-10000', status: 'Not Paid' },
        { id: '6', name: 'Karthikeyan S', customerId: 'B039', date: '24 / 05 / 2024', scheme: 'CAA-20000', status: 'Paid' },
        { id: '7', name: 'Rajiv G M', customerId: 'B048', date: '24 / 05 / 2024', scheme: 'CAE-100000', status: 'Paid' },
        { id: '8', name: 'SenthiNathan R', customerId: 'B052', date: '24 / 05 / 2024', scheme: 'BAF-10000', status: 'Not Paid' },
        { id: '9', name: 'Karthikeyan S', customerId: 'B039', date: '24 / 05 / 2024', scheme: 'CAA-20000', status: 'Paid' },
        { id: '10', name: 'Karthikeyan S', customerId: 'B039', date: '24 / 05 / 2024', scheme: 'CAA-20000', status: 'Paid' },
    ];
    

  return (
    <>
      <div className='search-filter-out'>
         <div className='search-cont'> <h4>
         <SearchIcon  className='mat-icon' />
           Search... </h4> <p> You can search by scheme,scheme no..</p></div>
         <div className='sync-filt-out'>
         <div className='sync-cont' onClick={handleClick}>
         <SyncIcon className={`mat-icon ${isSpinning ? 'spin' : ''}`} />
            Refresh</div>
         <div className='filter-cont' onClick={handleFilterClick}>
         <TuneIcon className='mat-icon' />
            Filter </div> </div>
      </div>
      {isPopupVisible && <FilterCustMan onClose={handleClosePopup} />}
      <div className='admin-crm-out'>
      <div className="table-wrapper">
                <table>
<thead>
    <tr>
    <th className="header-cell">
                                    <div className="header-content">
                                        <Checkbox /> Full Name
                                        <Icon icon="icon-park-solid:sort" className="sort-icon" />
                                    </div>
                                </th>
                                <th className="header-cell">
                                    <div className="header-content">
                                        Customer ID
                                        <Icon icon="icon-park-solid:sort" className="sort-icon" />
                                    </div>
                                </th>
                                <th className="header-cell">
                                    <div className="header-content">
                                        Date Created
                                        <Icon icon="icon-park-solid:sort" className="sort-icon" />
                                    </div>
                                </th>
                                <th className="header-cell">
                                    <div className="header-content">
                                        Scheme
                                        <Icon icon="icon-park-solid:sort" className="sort-icon" />
                                    </div>
                                </th>
                                <th className="header-cell">
                                    <div className="header-content">
                                        Status
                                        <Icon icon="icon-park-solid:sort" className="sort-icon" />
                                    </div>
                                </th>
                                <th className="header-cell">
                                    <div className="header-content">
                                        <Icon icon="line-md:edit-twotone" className="edit-icon" />
                                    </div>
                                </th>
                            </tr>
                        </thead>

                    <tbody>
                        {customers.map(customer => (
                            <tr key={customer.id}>
                                <td>
                                    <Checkbox
                                        checked={checkedItems[customer.id] || false}
                                        onChange={() => handleCheckboxChange(customer.id)}
                                    />
                                    {customer.name}
                                </td>
                                <td>{customer.customerId}</td>
                                <td>{customer.date}</td>
                                <td>{customer.scheme}</td>
                                <td className="status-cell">
                <span className={customer.status === 'Paid' ? 'status-paid' : 'status-not-paid'}>
                    {customer.status}
                </span>
            </td>
            <td>
                                    <IconButton>
                                        <MoreVert />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
              

                </div>
                <div className='download-out'> 
                  <div className='down-cont'>
                  <Icon icon="ph:download" className="download-cont-icon" /> <span>Download Selected</span>
                  </div>
             <div className='show-no-cont'>
                    <div className='show-cont'>Showing  <div className='count-cont'>10 <Icon icon="iconamoon:arrow-down-2-bold" className='down-icon-arrow'/> </div>
                    <div className='count-status'>1 - 10 of 30</div> <div className='arrow-cont'><Icon icon="iconamoon:arrow-left-2-bold" className='down-icon-arrow' /><Icon icon="iconamoon:arrow-right-2-bold" className='down-icon-arrow' /> </div>
                    </div>

             </div>


                </div>
               
            </div>

    </>
  )
}

export default CustomerManData
