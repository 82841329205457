import React, { useState, useEffect } from 'react';

const Welcont = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); 
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize); 
  }, []);

  return (
    <>
      {isMobile && (
        <div className='wel-cont'>
          <h3>Welcome to Ram Thanganagai Maligai</h3>
          <p>Join our community and manage your chit funds effortlessly. Enjoy secure and convenient access to all your financial needs.</p>
        </div>
      )}
    </>
  );
};

export default Welcont;
