import React from 'react';
import './AddSchemePop.css';

const AddSchemePop = ({ show, onClose, onProceed }) => {
  if (!show) return null;

  return (
    <div className="popup-overlay">
      <button onClick={onClose} className="close-button">&times;</button>
      <div className="popup-container">
        <div className="popup-header">
          <h2>User Details</h2>
        </div>
        <div className="popup-body">
          <div className="form-group">
            <label>User Name / Mobile Number</label>
            <input type="text" value="ThofaTech2024" readOnly />
          </div>
          <div className="form-group">
            <label>Customer ID</label>
            <input type="text" value="B031" readOnly />
          </div>
          </div>
          <div className='popup-2-bottom-scheme'>
            <button onClick={onProceed} className='proceed-button-add-scheme'>Proceed</button>
            <button onClick={onClose} className='cancel-button'>Cancel</button>
          </div>

          
      </div>
    </div>
  );
};

export default AddSchemePop;
