
import React from 'react';
import { useState, useEffect } from 'react';
const MemberCons = ({ schemeData }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1133);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (!schemeData) {
    return <p>Loading...</p>;
  }

  const scheme = schemeData.customerEnrolledSchemeDetail;
  //console.log("Member container:",scheme)
  return (
    <div className='go-back-out'>
     {isMobile && <div className='my-saving-scheme-detail'>My Saving Scheme details</div> }
      <div className='scheme-out-cont-2'>
       <div className= 'scheme-out-rev-mob'>  
        <div className='scheme-container-2'>
          <div className='scheme-detail-2'>
            <span className='label'>Scheme Name</span>
            <span className='value2'>{scheme.groupId}</span>
          </div>
          <div className='scheme-detail-2'>
            <span className='label'>Scheme No.</span>
            <span className='value2'>{scheme.schemeId || 'N/A'}</span>
          </div>
          <div className='scheme-detail-2'>
            <span className='label'>Amount</span>
            <span className='value2'>₹ {scheme.amount} /-</span>
          </div>
          <div className='scheme-detail-2'>
            <span className='label'>Group Code:</span>
            <span className='value2'>{scheme.groupCode || 'N/A'}</span>
          </div>
        </div>

        <div className='mob-name-out'>
          <div className='mob-nam-cont'><h3>Full Name</h3><p>{scheme.fullName}</p></div>
          <div className='mob-nam-cont'><h3>Mobile Number</h3><p>+91 {scheme.mobileNumber}</p></div>
          <div className='mob-nam-cont'><h3>Email</h3><p>{scheme.email || '-'}</p></div>
          <div className='mob-nam-cont'><h3>Payment Status</h3>  <p className={scheme.paymentStatus === 'Paid' ? 'paid-color' : 'unpaid-color'}>
                                   {scheme.paymentStatus}
                             </p></div>
        </div>
        </div>
        <div className='mat-upc-out'>
          <div className='mat-dat-cont'><h3>Maturity Date</h3><p>{new Date(scheme.maturityDate).toLocaleDateString('en-GB')}</p></div>
          <div className='mat-dat-cont'> <h3>Upcoming Due Date:</h3>
           <p>
                {scheme.upComingDueDate
                     ? new Date(scheme.upComingDueDate).toLocaleDateString('en-GB')
                     : "Null"}
               </p></div>
        </div>
      </div>
    </div>
  );
};

export default MemberCons;