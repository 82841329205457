import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import './Forgotpassword.css';
import { useNavigate, Link } from 'react-router-dom';
import WelcomePage from './WelcomePage';
import logo from '../common/asset1/rtm-logo.gif';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import LoadingOverlay from '../pages/LoadingOverlay';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Error as ErrorIcon } from '@mui/icons-material';
import successtick from '../common/asset1/tick-mark.png';
import Welcont from './Welcont';
import CopyRightSigin from './CopyRightSigin';
import Footer from '../common/Footer';
import warningIcon from '../common/asset1/warning-symbol.svg';
import { setIsLoading } from '../../Redux/store/authSlice';
import {  useDispatch } from 'react-redux';
const ForgotAuthForm = ({ role }) => {
  const [loginType, setLoginType] = useState('otp');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState(''.padEnd(6, ''));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const otpRefs = useRef([]);
  const [otpVerified, setOtpVerified] = useState(false);
  const [error, setError] = useState('');
  //const [isLoading, setIsLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); 
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [isTablet, setIsTablet] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    number: false,
    noSpaces: false,
  });
  const dispatch = useDispatch();
  const [isPasswordFocused, setIsPasswordFocused] = useState(false); 
  //console.log("Authorization Token:", token);
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 577 && window.innerWidth <= 1133);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
const handleNavigation = (path) => {
  dispatch(setIsLoading(true));
  setTimeout(() => {
   dispatch(setIsLoading(false));
  },200);
  navigate(path);
};
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); 
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const prefix = '+91';
  
  useEffect(() => {
    const validatePasswordRequirements = () => {
      setPasswordValidations({
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        number: /[0-9]/.test(password),
        noSpaces: !/\s/.test(password),
      });
    };
    validatePasswordRequirements();
  }, [password]);
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true); 
  };
  const passwordsMatch = password === confirmPassword;
  
  const handleMobileNumberChange = (e) => {
    const inputValue = e.target.value.replace(prefix, ''); 
    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) { 
      setMobileNumber(inputValue);
    }
  };
  
  const isLoading = useSelector((state) => state.auth.isLoading);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
        setError('Unable to fetch IP address.');
      }
    };
    fetchIPAddress();
  }, []);

  const validateMobileNumber = (mobile) => /^[0-9]{10}$/.test(mobile);
  const validatePassword = (password) => password.length >= 8;

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      const updatedOtp = otp.split('');
      updatedOtp[index] = value;
      setOtp(updatedOtp.join(''));
    
    if (value !== '' && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    dispatch(setIsLoading(true));

    try {
      if (loginType === 'otp') {
        if (!otpVerified) {
          if (!validateMobileNumber(mobileNumber)) {
            setError('Invalid Mobile Number. It must contain exactly 10 digits.');
            dispatch(setIsLoading(false)); 
            return;
          }

          if (!isOtpSent) {
            const response = await axios.post(`${apiUrl}/otp`, {
              mobileNo: mobileNumber,
            },  );

            if (response.data.status === 'Success') {
              setSessionID(response.data.details);
              setIsOtpSent(true);
              dispatch(setIsLoading(false)); 
            } else {
              setError('Failed to send OTP. Please try again.');
              dispatch(setIsLoading(false)); 
            }
          } else if (otp.length === 6) {
            const response = await axios.post(`${apiUrl}/otp/verify`, {
              otp,
              sessionID,
              mobileNumber,
              ipAddress,
            }, );

            if (response.data === true) {
              setOtpVerified(true);
              setIsOtpSent(false);
              dispatch(setIsLoading(false)); 
            } else {
              setError('Invalid OTP or session expired. Please try again.');
              dispatch(setIsLoading(false)); 
            }
          } else {
            setError('Please enter a 6-digit OTP.');
            dispatch(setIsLoading(false)); 
          }
        } else {
          if (password === confirmPassword && validatePassword(password)) {
            const changePasswordResponse = await axios.post(`${apiUrl}/account/forgotpasswordforuser`, {
              sessionID,
              otp,
              newPassword: password,
              mobileNumber,
              confirmPassword,
              ipAddress,
            },  {
              headers: {
                'Content-Type': 'application/json',
               'Authorization': `Bearer ${token}`,
             },
           }
          );
            
            if (changePasswordResponse.data && changePasswordResponse.data.includes('Password Update successful')) {
              setOtpVerified(false);
              setIsOtpSent(false);
              setSuccessMessage('Password updated successfully.');
              setError('');
              dispatch(setIsLoading(false)); 
            } else {
              setError(changePasswordResponse.data.message || 'Failed to change password. Please try again.');
              dispatch(setIsLoading(false)); 
            }
          } else {
            setError('Passwords do not match or invalid password format.');
            dispatch(setIsLoading(false)); 
          }
        }
      }
    } catch (error) {
      if (error.response) {
       
        if (error.response.status === 400) {
          setError(error.response.data.message || 'Bad Request');
        } else if (error.response.status === 401) {
          if (error.response.data.message === "Invalid username or password") {
            setError('Invalid Username or Password. Please try again.');
          } else {
            setError('Invalid OTP. Please try again.');
          }
        } else {
          setError('Something went wrong. Please try again.');
        }
      } else {
        setError('Network error. Please check your connection.');
      }
      dispatch(setIsLoading(false)); 
    }
  };

  const handleLoginWithUserId = () => {
    setMobileNumber('');
    setPassword('');
    setConfirmPassword('');
    setError('');
    navigate('/signin');
  };
  return (
    <div className='auth-form-forgot-password'>
          {isLoading && <LoadingOverlay />}
         {!isMobile && (<img src={logo} alt="Logo" className="sub-logo" /> )} 
          
          <div className='button-group'>
            <button
              type="button"
              onClick={handleLoginWithUserId} 
              className={loginType === 'userid' ? 'active' : ''}
            >
              Login with User ID
            </button>
            <button
              type="button"
              onClick={() => setLoginType('otp')}
              className={loginType === 'otp' ? 'active bg-active' : ''}
            >
              Forgot Password
            </button>
          </div>
          {error &&   <div className="error-with-icon-out">
            <img src={warningIcon} alt="Warning-Icon" className="warning-icon" /> <div className="error-message-forgot-password">{error}</div></div>}
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              {loginType === 'otp' && (
                <>
                  {!otpVerified && !successMessage && (
                    <>
                      <label>Enter your registered mobile number to receive an OTP:</label>
                      <input
                type="text"
                value={`${prefix}${mobileNumber}`} 
                onChange={handleMobileNumberChange} 
                maxLength={13} 
                required
                placeholder="10-digit mobile number"
              />
                      {isOtpSent && (
                        <>
                          <label>Enter the OTP:</label>
                          <div className='otp-container'>
                          {[...Array(6)].map((_, i) => (
                              <input
                                key={i}
                                type="text"
                                value={otp[i] || ''}
                                onChange={(e) => handleOtpChange(e, i)}
                                maxLength={1}
                                className="otp-input"
                                ref={(el) => (otpRefs.current[i] = el)}
                                required
                                placeholder="0"
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {(otpVerified || successMessage) && (
                     <>
                     {!successMessage && ( 
                    <>
                    
                      <div className="form-group">
                        <label>New Password</label>
                        <div className="password-container">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={handlePasswordFocus} 
                            required
                          />
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={() => setShowPassword(!showPassword)}
                            className="password-icon"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="password-container">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="password-icon"
                          />
                     
                        </div>
                      </div>
                      {isPasswordFocused && (
                      <div className="password-validation">
                         {confirmPassword.length > 1 && (
          <div style={{ color: passwordsMatch ? '#45A145' : '#E51A1A' }}>
            {passwordsMatch ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#E51A1A', marginRight: '8px' }} />
            )}
            {passwordsMatch ? "Passwords match" : "Passwords do not match"}
          </div>)}
          <div style={{ color: passwordValidations.length ? '#45A145' : '#808080' }}>
            {passwordValidations.length ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' }} />
            )}
            Password must be at least 8 characters long
          </div>
          <div style={{ color: passwordValidations.uppercase ? '#45A145' : '#808080', display: 'flex', alignItems: 'center'}}>
            {passwordValidations.uppercase ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px', verticalAlign: 'middle' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' , verticalAlign: 'middle'  }} />
            )}
            Password must contain at least one uppercase letter
          </div>
          <div style={{ color: passwordValidations.number ? '#45A145' : '#808080' }}>
            {passwordValidations.number ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must contain at least one number
          </div>
          <div style={{ color: passwordValidations.noSpaces ? '#45A145' : '#808080' }}>
            {passwordValidations.noSpaces ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must not contain spaces
             </div>
               </div>    )}
                    </>
                  )}
                </>
              )}
               
          
          
            </>
            
          )}

              {loginType === 'userid' && (
                <>
                  <label>Enter User ID:</label>
                  <input
                    type="text"
                    required
                    placeholder="User ID"
                  />
                  <label>Enter Password:</label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={() => setShowPassword(!showPassword)}
                    className="password-icon"
                  />
                </>
              )}

              {successMessage && (
                <div className="success-container">
                  <div className='success-cont-inner'>
                <img src={successtick} alt="Success" className="success-tick-mark" />
                <div className="success-message">
                  {successMessage} </div>
                  <button
                    type="button"
                    onClick={() => navigate('/signin')}
                    className="btn btn-login-now"
                  >
                    Login Now
                  </button>
                  </div>
                </div>
              )}
           
            </div> 

            {!successMessage && (
              <button type="submit" className="btn btn-reset">
                {isLoading ? 'Processing...' : otpVerified ? 'Change Password' : isOtpSent ? 'Verify OTP' : 'Send OTP'}
              </button>
            )}

           
            <div className="form-group-newuser">
                <label>New User?</label>
                <Link to="/registernow" className="register-name"  onClick={() => handleNavigation('/registernow')}>
                  Register now
                </Link>
              </div>
             
          </form>
          {isTablet &&  <Footer/> }
        </div>
  )
}

export default ForgotAuthForm
