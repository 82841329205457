import React from 'react'
import './Home.css';
import { Outlet } from 'react-router-dom';
import Sidebar from '../common/Sidebar';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

const AdminHome = () => {
  return (
    <>
    <Header></Header>
    <div className='main-home-container'>
         
      <Sidebar/>
      <div className='whole-home-content'>
        <Outlet/>
   <Footer></Footer>
      </div>
     </div>
   
   </>
  )
}

export default AdminHome
