import React from 'react';
import './Footer.css'
const Footer = () => {
  return (
    <footer className="footer-cont">
<div className='copy-cont'>
  Copyright © 2024 Ram Thanganagai Maligai | Powered by   
  
  <span className="tech-container"> 
          <span className="thofa-text">THOFA</span>
          <span className="technologies-text"> TECHNOLOGIES</span>
        </span>
      </div>
    <div className='pri-cont'>Privacy Policy | Terms and Conditions </div>
    </footer>
  );
};

export default Footer;
