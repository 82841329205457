import React from 'react'
import MobLogo from './MobLogo'
import './MobSignIn.css';
import AuthForm from './AuthForm';
import Footer from '../common/Footer';
import Welcont from './Welcont';
const MobSignIn = () => {
   

  return (
    <>
    <div className='mob-sign-out'>
        
        <MobLogo className='mob-logo'/>
        <Welcont/>
        <AuthForm className='mob-auth-form'/>
        <Footer className='mob-footer'/>
    </div>
    </>
  )
}

export default MobSignIn
