import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Signin.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import WelcomePage from './WelcomePage';
import logo from '../common/asset1/rtm-logo.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setUser, setIsLoading } from '../../Redux/store/authSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CopyRightSigin from './CopyRightSigin';
import Welcont from './Welcont';
import Footer from '../common/Footer';
import warningIcon from '../common/asset1/warning-symbol.svg';
import { toast } from "react-hot-toast";

//const Roles = {
  //Admin: 0,
 // User: 1,
//};
const AuthForm = () => { 
  //const [isLoading, setIsLoading] = useState(false);
 
  const [loginType, setLoginType] = useState('userID');
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [otp, setOtp] = useState(''.padEnd(6, ''));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [sessionID, setSessionID] = useState(''); 
  const [showPassword, setShowPassword] = useState(false);
  const otpRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token) || localStorage.getItem('accessToken');
  //console.log("Authorization Token:", token);
  const user = useSelector((state) => state.auth.user);
  const [newUser, setNewUser] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const role = useSelector((state) => state.auth.role);
  const handleSetUser = () => {  
    dispatch(setUser(newUser));
  }

  const handleNavigation = (path) => {
    dispatch(setIsLoading(true));
    setTimeout(() => {
     dispatch(setIsLoading(false));
    },500);
    navigate(path);
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <=576);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 577 && window.innerWidth <= 1133);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const apiUrl = process.env.REACT_APP_API_URL;
   //console.log("Environment:", process.env.NODE_ENV);
  // console.log("API URL:", apiUrl);
  const fetchIPAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return 'Unable to fetch IP';
    }
  };

  const validateUserID = (userID) => {
    const userIDRegex = /^[a-zA-Z0-9._-]+$/;
    const numericOnlyCheck = /^\d+$/;
    return userIDRegex.test(userID) && !numericOnlyCheck.test(userID);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const userDetail = useSelector(state => state.auth.user);
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("Submitting form with:", { userID, password, mobileNumber, loginType });
    dispatch(setIsLoading(true));
    setError('');
    const roleBasedLoginType = role === 'admin' ? 1 : 0;
    //const roleBasedLoginType = role === 'admin' ? Roles.Admin : Roles.User;

    try {
      const ipAddress = await fetchIPAddress(); 
      //console.log('My IP Address:', ipAddress);
      if (loginType === 'userID') {
        
        if (!validateUserID(userID)) {
          setError(
            'Invalid User Name.'
          );
          dispatch(setIsLoading(false));
          return;
        }

        if (password.trim() === '') {
          setError('Password is required.');
          dispatch(setIsLoading(false));
          return;
        }

       // console.log('UserID:', userID);
       // console.log('Password:', password);

        const response = await axios.post(`${apiUrl}/account/login`, {
          CustomerID: userID,
          userName: userID,
          email: '',
          password: password,
          //loginType: roleBasedLoginType,
          loginType:1,
          ipAddress: ipAddress,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {

          const data = response.data;
          //console.log('API Response:', data);
          const { userID, userName, token,fullName } = data;
          const userRole = roleBasedLoginType;

          const userData = { userID, userName, token, role: userRole , fullName};
          //console.log('what kind of role:',userRole)
          setNewUser(token);
          handleSetUser();
  
          localStorage.setItem('accessToken', token);
          localStorage.setItem('role', userRole);
          localStorage.setItem('userID', userID);
          localStorage.setItem('userName', userName);
          localStorage.setItem('fullName',fullName);
          //console.log('User Data (JSON):', JSON.stringify(userData));

          dispatch(setUser({ userID, userName, token, role: userRole, ipAddress,fullName }));
          
         ;
          toast.success('Login successful!');
          dispatch(setIsLoading(false));
          if (role === 'admin') {
            navigate('/admin/home/customer-management', { replace: true });
          } else {
            navigate('/home/my-saving-scheme', { replace: true });
          }
        } else {
          setError('Invalid User ID or Password');
          dispatch(setIsLoading(false))
         ;
        }

      } else if (loginType === 'otp') {
        if (!isOtpSent) {
          if (!validateMobileNumber(mobileNumber)) {
            setError('Invalid Mobile Number. It must contain exactly 10 digits.');
           ;
            return;
          }
          dispatch(setIsLoading(true));
          const otpResponse = await axios.post(`${apiUrl}/otp`, {
            mobileNo: mobileNumber,
          }, {
            headers: {
              'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
          });
         // console.log("OTP API Authorization Token:", token);
          if (otpResponse.data.status === 'Success') {
            const receivedSessionID = otpResponse.data.details;
            setSessionID(receivedSessionID);
            setIsOtpSent(true);
            dispatch(setIsLoading(false))
           ;
          } else {
            setError('Failed to send OTP. Please try again.');
            dispatch(setIsLoading(false))
           ;
          }

        } else {
          if (otp.length !== 6) {
            setError('Please enter a 6-digit OTP.');
           ;
            return;
          }
          dispatch(setIsLoading(true))
          const verifyResponse = await axios.post(`${apiUrl}/account/login/otp/verify`, {
         
            otp,
            sessionID,
            mobileNumber,
            ipAddress,
          }, {
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          });
        //  console.log("OTP Verify API Authorization Token:", token); 

          if (verifyResponse.data && verifyResponse.data.userID)  {
            const { userID, userName, token } = verifyResponse.data;
            const userRole = roleBasedLoginType;

            const userData = { userID, userName, token, role: userRole };
           // console.log("Userdata:", userData);
            setNewUser(token);
            handleSetUser();

            localStorage.setItem('accessToken', token);
            localStorage.setItem('role', userRole);
            localStorage.setItem('userID', userID);
            localStorage.setItem('userName', userName);

            dispatch(setUser({ userID, userName, token, role: userRole, ipAddress }));

           ;
            navigate('/home/my-saving-scheme', { replace: true });
            dispatch(setIsLoading(false))
          } else {
            setError('Invalid OTP. Please try again.');
            dispatch(setIsLoading(false))
           ;
          }
        }
      }
    } catch (error) {
      dispatch(setIsLoading(false))
      if (error.response) {
        if (error.response.status === 400) {
          setError(error.response.data.message || 'Bad Request');
          
        } else if (error.response.status === 401) {
          if (error.response.data.message === "Invalid username or password") {
            setError('Invalid Username or Password. Please try again.');
          } else {
            setError('Invalid OTP or session expired. Please try again.');
          }
        } else {
          setError('Something went wrong. Please try again.');
        }
      } else {
        setError('Network error. Please check your connection.');
      }
      dispatch(setIsLoading(false));
    }
  };
  const handleLoginTypeChange = (type) => {
    setLoginType(type);
    setError('');
    setIsOtpSent(false);
    setUserID('');
    setPassword(''); 
    setMobileNumber(''); 
    setOtp(''.padEnd(6, '')); 
    setSessionID(''); 
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/\D/, '');
    setOtp((prev) => prev.substring(0, index) + value + prev.substring(index + 1));
    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    
    <div className="auth-form">
       
   {!isMobile && <img src={logo} alt="Logo" className="sub-logo" /> }
  
    {role === 'user' && (
      <div className="button-group">
        <button
          type="button"
          onClick={() => handleLoginTypeChange('userID')}
          className={loginType === 'userID' ? 'active' : ''}
        >
          Login with User ID
        </button>
        <button
          type="button"
          onClick={() => handleLoginTypeChange('otp')}
          className={loginType === 'otp' ? 'active' : ''}
        >
          Login with Mobile
        </button>
      </div>
    )}
                  {error && (
                <div className="error-with-icon-out">
              <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
                    <div className="error-message">{error}</div>
                   </div>
              )}
    {role === 'admin' && <div className="welcome-admin-tag">Welcome Admin!</div>}
    <form onSubmit={handleSubmit}>
      {!isOtpSent ? (
        <>
          <div className="form-group">
            <label>{loginType === 'userID' ? 'User Name' : 'Mobile Number'}</label>
            {loginType === 'userID' ? (
              <input
                type="text"
                value={userID}
                onChange={(e) => {
                  const value = e.target.value;
                    const userIDRegex = /^[a-zA-Z0-9_.@-]*$/;
                  if (userIDRegex.test(value)) {
                    setUserID(value);
                  }
                }}
                required
              />
            ) : (
              <div className="input-container">
                <span className="country-code">+91 </span>
                <input
                  type="text"
                  value={mobileNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) { 
                      setMobileNumber(value);
                    }
                  }}
                  required
                />
              </div>
            )}
            
          </div>
          {loginType === 'userID' ? (
            <>
              <div className="form-group">
                <label>Password</label>
                <div className="input-password-container">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={toggleShowPassword}
                    className="password-icon"
                  />
                </div>
                <Link to="/forgotpassword" className="forgot-password" onClick={() => handleNavigation('/forgotpassword')}>
                  Forgot password?
                </Link>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-login">
                  {isLoading ? (
                    <span>
                      <i className="fa fa-refresh fa-spin"></i>&nbsp;Loading...
                    </span>
                  ) : (
                    'Login'
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="form-group">
              <button type="submit" className="btn btn-login proceed-button">
                {isLoading ? (
                  <span>
                    <i className="fa fa-refresh fa-spin"></i>&nbsp;Loading...
                  </span>
                ) : (
                  'Proceed'
                )}
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="form-group">
            <label>Kindly enter the OTP that has been sent to the entered mobile number</label>
            <div className="otp-container">
              {[...Array(6)].map((_, i) => (
                <input
                  key={i}
                  type="text"
                  value={otp[i] || ''}
                  onChange={(e) => handleOtpChange(e, i)}
                  maxLength={1}
                  ref={(el) => (otpRefs.current[i] = el)}
                  className="otp-input"
                  required
                />
              ))}
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-login">
              {isLoading ? (
                <span>
                  <i className="fa fa-refresh fa-spin"></i>&nbsp;Loading...
                </span>
              ) : (
                'Verify OTP'
              )}
            </button>
          </div>
        </>
      )}

      {role === 'user' && (
        <div className="form-group-newuser">
          <label>New User?</label>
          <Link to="/registernow" className="register-name" onClick={() => handleNavigation('/registernow')}>
            Register now
          </Link>
        </div>
      )}
    </form>
    {isTablet &&  <Footer/> }
  
  </div>
  )
}

export default AuthForm
