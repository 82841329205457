import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import RazorpayPayment from '../../components/pages/RazorpayPayment';
import { toast } from 'react-toastify';
import { useDispatch , useSelector} from 'react-redux';
import { setTransactionData, clearTransactionData } from '../../Redux/store/transactionSlice';
import { useNavigate } from 'react-router-dom';

// Uncomment if you have a CSS file
// import './SchemeInformation.css';

const AdminSchemeTicket = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [paymentData, setPaymentData] = useState(null); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth.role);


  const handleClick = () => {
    navigate('/admin/home/scheme-information');
  };

  const viewdetailhandleClick = () => {
    navigate('/admin/home/viewing-user-profile');
  };

  const handleClickPaynow = () => {
    setShowPopup(true);
  };

  const handleProceed = () => {
    if (!selectedPaymentMethod) {
        alert('Please select a payment method');
        return;
    }

    const data = {
        scheme_uuid: 'unique-scheme-uuid',
        transactionId: 'unique-transaction-id',
        schemeName: 'Scheme Name',
        amount: 500,
        user_id: 'user-id',
        name: 'Avinash M',
        email: 'example@domain.com',
        contact: '+91 89469 98462',
    };
    dispatch(setTransactionData(data));
    setPaymentData(data); 
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setSelectedPaymentMethod('');
    dispatch(clearTransactionData());
  };

  const handlePaymentSuccess = (response) => {
    console.log('Payment Successful:', response);
    toast.success('Payment was successful!');
    navigate('/admin/home/add-new-scheme'); // Updated navigation path
    dispatch(clearTransactionData());
  };

  const handlePaymentFailure = (response) => {
    console.log('Payment Failed:', response);
    toast.error('Payment failed, please try again.');
    dispatch(clearTransactionData());
    navigate('/admin/home/add-new-scheme'); 
  };

  return (
    <div className='scheme-ticket-cont'>
      <div className='manage-sav-cont'>
        <h2>Viewing User’s Profile</h2>
        <button className='new-scheme-btn' onClick={handleClick}>
          New Scheme
          <FontAwesomeIcon icon={faPlus} className='plus-icon' />
        </button>
      </div>

      <div className="member-scheme">    
        <div className="maturity-cont">
          <div className='due-cont'>
            <div className='due-filled'>
              <span>08</span>
              <span>11</span>
            </div>
            <div className='mat-date-cont'>
              <h3>Maturity Date</h3>
              <h2>15 / 06 / 2024</h2>
            </div>
          </div>
          <div className="scheme-container">
            <div className="scheme-detail">
              <span className="label">Scheme Name</span>
              <span className="value">BAB - 500</span>
            </div>
            <div className="scheme-detail">
              <span className="label">Amount</span>
              <span className="value">₹ 500 /-</span>
            </div>
            <div className="scheme-detail">
              <span className="label">Membership No.</span>
              <span className="value">B031</span>
            </div>
            <div className="scheme-detail">
              <span className="label">Group Code:</span>
              <span className="value">G2</span>
            </div>
          </div>
          <div className='pay-detail'>
            <h3>Payment Status:</h3>
            <p>2 Months Pending</p>
          </div>
        </div>
        
        <div className="id-cont">
          <div className='profile-cont'>
            <div className='profile-out-cont'>
              <FontAwesomeIcon icon={faUser} className='profile-icon' />
            </div>
            <div className='profile-det'>
              <p>Avinash M</p>
              <p>+91 89469 98462</p>
              <p>Male</p>
            </div>
          </div>
          <div className='btn-group-pay'>
            <button className='btn-view-details' onClick={viewdetailhandleClick}>View Details</button>
            <button className='btn-pay-now' onClick={handleClickPaynow}>Pay Now</button> {/* Added onClick handler */}
          </div>
          <div className='upcoming-due-date'>
            <h3>Upcoming Due Date:</h3>
            <p>12 / 08 / 2024</p>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className='popup-overlay-payment-slot'>
          <div className='popup-payment-slot'>
            <h3>Mode of Payment</h3>
            <div className='payment-method-payment-slot'>
              <label>
                Payment Method
              </label>
              <select
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              >
                <option value="">Select a payment method</option>
                <option value="creditCard">Credit Card</option>
                <option value="paypal">PayPal</option>
                <option value="bankTransfer">Bank Transfer</option>
              </select>
            </div>

            <div className='popup-buttons-payment-slot'>
              <button className='payment-proceed' onClick={handleProceed}>Proceed</button>
              <button className='payment-cancel' onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {paymentData && (
        <RazorpayPayment
          transactionData={paymentData}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={handlePaymentFailure}
        />
      )}
    </div>
  );
}

export default AdminSchemeTicket;


/*{import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import RazorpayPayment from '../../components/pages/RazorpayPayment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setTransactionData, clearTransactionData } from '../../Redux/store/transactionSlice';
import { useNavigate } from 'react-router-dom';

// Uncomment if you have a CSS file
// import './SchemeInformation.css';

const AdminSchemeTicket = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [paymentData, setPaymentData] = useState(null); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/admin/home/scheme-information');
  };

  const viewdetailhandleClick = () => {
    navigate('/admin/home/viewing-user-profile');
  };

  const handleClickPaynow = () => {
    setShowPopup(true);
  };

  const handleProceed = () => {
    if (!selectedPaymentMethod) {
        alert('Please select a payment method');
        return;
    }

    const data = {
        scheme_uuid: 'unique-scheme-uuid',
        transactionId: 'unique-transaction-id',
        schemeName: 'Scheme Name',
        amount: 500,
        user_id: 'user-id',
        name: 'Avinash M',
        email: 'example@domain.com',
        contact: '+91 89469 98462',
    };
    dispatch(setTransactionData(data));
    setPaymentData(data); 
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setSelectedPaymentMethod('');
    dispatch(clearTransactionData());
  };

  const handlePaymentSuccess = (response) => {
    console.log('Payment Successful:', response);
    toast.success('Payment was successful!');
    navigate('/admin/home/my-saving-scheme'); // Updated navigation path
    dispatch(clearTransactionData());
  };

  const handlePaymentFailure = (response) => {
    console.log('Payment Failed:', response);
    toast.error('Payment failed, please try again.');
    dispatch(clearTransactionData());
  };

  return (
    <div className='scheme-ticket-cont'>
      <div className='manage-sav-cont'>
        <h2>Viewing User’s Profile</h2>
        <button className='new-scheme-btn' onClick={handleClick}>
          New Scheme
          <FontAwesomeIcon icon={faPlus} className='plus-icon' />
        </button>
      </div>

      <div className="member-scheme">    
        <div className="maturity-cont">
          <div className='due-cont'>
            <div className='due-filled'>
              <span>08</span>
              <span>11</span>
            </div>
            <div className='mat-date-cont'>
              <h3>Maturity Date</h3>
              <h2>15 / 06 / 2024</h2>
            </div>
          </div>
          <div className="scheme-container">
            <div className="scheme-detail">
              <span className="label">Scheme Name</span>
              <span className="value">BAB - 500</span>
            </div>
            <div className="scheme-detail">
              <span className="label">Amount</span>
              <span className="value">₹ 500 /-</span>
            </div>
            <div className="scheme-detail">
              <span className="label">Membership No.</span>
              <span className="value">B031</span>
            </div>
            <div className="scheme-detail">
              <span className="label">Group Code:</span>
              <span className="value">G2</span>
            </div>
          </div>
          <div className='pay-detail'>
            <h3>Payment Status:</h3>
            <p>2 Months Pending</p>
          </div>
        </div>
        
        <div className="id-cont">
          <div className='profile-cont'>
            <div className='profile-out-cont'>
              <FontAwesomeIcon icon={faUser} className='profile-icon' />
            </div>
            <div className='profile-det'>
              <p>Avinash M</p>
              <p>+91 89469 98462</p>
              <p>Male</p>
            </div>
          </div>
          <div className='btn-group-pay'>
            <button className='btn-view-details' onClick={viewdetailhandleClick}>View Details</button>
            <button className='btn-pay-now' onClick={handleClickPaynow}>Pay Now</button> {/* Added onClick handler *//*}
            </div>
            <div className='upcoming-due-date'>
              <h3>Upcoming Due Date:</h3>
              <p>12 / 08 / 2024</p>
            </div>
          </div>
        </div>
  
        {showPopup && (
          <div className='popup-overlay-payment-slot'>
            <div className='popup-payment-slot'>
              <h3>Mode of Payment</h3>
              <div className='payment-method-payment-slot'>
                <label>
                  Payment Method
                </label>
                <select
                  value={selectedPaymentMethod}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                >
                  <option value="">Select a payment method</option>
                  <option value="creditCard">Credit Card</option>
                  <option value="paypal">PayPal</option>
                  <option value="bankTransfer">Bank Transfer</option>
                </select>
              </div>
  
              <div className='popup-buttons-payment-slot'>
                <button className='payment-proceed' onClick={handleProceed}>Proceed</button>
                <button className='payment-cancel' onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        )}
  
        {paymentData && (
          <RazorpayPayment
            transactionData={paymentData}
            onPaymentSuccess={handlePaymentSuccess}
            onPaymentFailure={handlePaymentFailure}
          />
        )}
      </div>
    );
  }
  
  export default AdminSchemeTicket;
  } */




