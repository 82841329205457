import React from 'react'
import CustManCards from './CustManCards'
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

const SchemeInformation = () => {
  const navigate=useNavigate();
  const onClickGoBack = () => {
    navigate('/admin/home/customer-management');
  };
  return (
   <>
    <div className="whole-mysave-cont">
   <CustManCards/>

   <div className='new-scheme-outer-cont'>

   <button className="get-back-out" onClick={onClickGoBack}>
          <Icon icon="iconamoon:arrow-left-2-bold" className="go-back-symbol" />
          Go Back
        </button>
        <div className='join-title'>Hi, User! Join a New Scheme</div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='scheme-name'>Scheme Name</label>
            <select id='scheme-name' className='form-input'>
              <option>Select Scheme</option>
            
            </select>
          </div>

          <div className='rhs-cont'>
            <label htmlFor='amount-per-month'>Amount per month</label>
            <input type='text' id='amount-per-month' className='form-input' placeholder='Select a scheme to know the amount' readOnly />
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='full-name'>Full Name</label>
            <input type='text' id='full-name' className='form-input' placeholder='Full Name' />
          </div>

          <div className='rhs-cont'>
            <label htmlFor='gender'>Gender</label>
            <select id='gender' className='form-input'>
              <option>Select Gender</option>
              
            </select>
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='alt-mobile-number'>Alternative Mobile Number</label>
            <input type='text' id='alt-mobile-number' className='form-input' placeholder='+91' />
          </div>

          <div className='rhs-cont'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' className='form-input' placeholder='Type your email' />
          </div>
        </div>

        <div className='address-out'>
          <div className='scheme-address-cont'>
            <label htmlFor='address'>Address</label>
            <textarea id='address' className='form-input' placeholder=''></textarea>
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='city'>City</label>
            <select id='city' className='form-input'>
              <option>Select city name</option>
              
            </select>
          </div>

          <div className='rhs-cont'>
            <label htmlFor='state'>State</label>
            <select id='state' className='form-input'>
              <option>Select State</option>
             
            </select>
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='pincode'>Pincode</label>
            <input type='text' id='pincode' className='form-input' placeholder='605008' />
          </div>

          <div className='rhs-cont'>
            <label htmlFor='country'>Country</label>
            <div id='country' className='form-input'>
              <option>India</option>
            
            </div>
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='dob'>Date of Birth</label>
            <input type='date' id='dob' className='form-input' />
          </div>

          <div className='rhs-cont'>
            <label htmlFor='anniversary'>Date of Anniversary</label>
            <input type='date' id='anniversary' className='form-input' />
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='id-proof'>Select ID Proof</label>
            <select id='id-proof' className='form-input'>
              <option>Select ID Proof</option>
             
            </select>
          </div>

          <div className='rhs-cont'>
            <label htmlFor='id-proof-number'>ID Proof Number</label>
            <input type='text' id='id-proof-number' className='form-input' placeholder='DX487A569' />
          </div>
        </div>

        <div className='scheme-name-out'>
          <div className='scheme-name-cont'>
            <label htmlFor='pan-number'>Pan number *</label>
            <input type='text' id='pan-number' className='form-input' placeholder='Pan Number' />
            <p>Note: If the scheme amount is above ₹2 Lakhs, PAN number is must for verification purposes.</p>
          </div>
        
        </div>
        <button className='btn-sub-nscheme'>Submit</button>
      </div>
     
   </div>
   </>
  )
}

export default SchemeInformation
