import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import akshayabanner from './asset1/akshaya.png';
import AddSchemePop from '../pages/AddSchemePop';

const Sidebar = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleLinkClick = (path) => {
    if (path === '/admin/home/add-new-scheme') {
      setShowPopup(true);
    } else {
      navigate(path);
    }
  };

  const handleProceed = () => {
    setShowPopup(false);
    navigate('/admin/home/add-new-scheme');
  };

  const adminLinks = [
    { to: '/admin/home/customer-management', name: 'Customer Management' },
    { to: '/admin/home/new-user-reg', name: 'New User Registration' },
    { to: '/admin/home/add-new-scheme', name: 'Add a new scheme' },
    { to: '/admin/home/scheme-information', name: 'Scheme Information' },
    { to: '/admin/home/viewing-user-profile', name: 'View Profile' },
    { to: '/admin/home/close-user-scheme', name: 'Close Scheme' },
    { to: '/admin/home/deactivate-user', name: 'De-Activate User' },
    { to: '/admin/home/filter-scheme', name: 'Filter Scheme' },
    { to: '/admin/home/close-user-scheme', name: 'Close Scheme' },
  ];

  return (
    <div className='whole-sidebar'>
      <div className='content-sidebar'>
        <h2>Hi Admin!</h2>
        <p>Manage Details</p>
        <ul>
          {adminLinks.map((link, index) => (
            <li key={index}>
              <span onClick={() => handleLinkClick(link.to)}>{link.name} </span>
            </li>
          ))}
        </ul>
       
        <img
          src={akshayabanner}
          alt="Akshaya-Banner"
          className="akshaya-banner"
          onClick={toggleFullscreen}
        />
      </div>

      {isFullscreen && (
        <div className="fullscreen-overlay">
          <div className="fullscreen-content">
            <img
              src={akshayabanner}
              alt="Akshaya-Banner"
              className="akshaya-banner-fullscreen"
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={toggleFullscreen}
            />
          </div>
        </div>
      )}


      <AddSchemePop show={showPopup} onClose={() => setShowPopup(false)} onProceed={handleProceed} />
    </div>
  );
};

export default Sidebar;
