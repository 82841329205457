import React, { useState } from 'react';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeActStaff from './DeActStaff';

const DeActProfile = () => {
  const [showDeActStaff, setShowDeActStaff] = useState(false); 
  const [showPopup, setShowPopup] = useState(false); 

  const handleDeactivateClick = () => {
    setShowPopup(true); 
  };

  const handleProceedClick = () => {
    setShowPopup(false);
    setShowDeActStaff(true); 
  };
  const handleCancelClick = () => {
    setShowPopup(false); 
  };
  return (
    <>
      {showDeActStaff ? (
        <DeActStaff /> 
      ) : (
        <div className='close-use-out'>
          <div className='close-use-cont'>Deactivate a User Account</div>

          <div className='de-act-cont'>
            <div className='de-act-cont-grp'>
              <div className='profile-out-cont-deact'>
                <FontAwesomeIcon icon={faUser} className='profile-icon-deact' />
              </div>
              <div className='de-act-id-cont'>
                <div className='de-act-entry'>
                  <h5>User Name</h5>
                  <p>Avi_9865</p>
                </div>
                <div className='de-act-entry'>
                  <h5>Name</h5>
                  <p>Avinash M</p>
                </div>
                <div className='de-act-entry'>
                  <h5>Mobile Number</h5>
                  <p>+91 99944 77889</p>
                </div>
                <div className='de-act-entry'>
                  <h5>Customer Id</h5>
                  <p>B031</p>
                </div>
                <div className='de-act-entry'>
                  <h5>Scheme Enrolled</h5>
                  <p>02</p>
                </div>
              </div>
            </div>
          </div>

          <button className="close-proceed" onClick={handleDeactivateClick}>
            Deactivate Account
          </button>
          {showPopup && (
            <div className='popup-overlay'>
            <div className="popup-mem-sch">
              <div className="popup-content-mem-sch">
                <p>Are you sure you want to deactivate and close this user’s account?</p>
                <div className="popup-buttons-mem-sch">
                  <button className="popup-btn-mem-sch proceed-btn-mem-sch" onClick={handleProceedClick}>Proceed</button>
                  <button className="popup-btn-mem-sch cancel-btn-mem-sch" onClick={handleCancelClick}>Cancel</button>
                </div>
              </div>
            </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DeActProfile;

