import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import logo from './asset1/rtm-logo.gif';
import title from './asset1/title1.svg';
import titleMobile from './asset1/title.svg';
import Headerunner from './Headerunner';
import { faUserCircle, faCog, faKey, faSignOutAlt, faBars  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../../Redux/store/authSlice';
import { logout } from '../../Redux/store/authSlice';
import {  ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Sidebar from './Sidebar'
import { toast } from "react-hot-toast";
import { setIsLoading} from '../../Redux/store/authSlice';
import SessionTimeout from '../authentication/SessionTimeout';

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [newUser, setNewUser] = useState('');
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.auth.isLoading); 
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1133);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

 
  useEffect(() => {
    const handleClickOutside = (event) => {
     
      if (sidebarOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen]); 


  const handleNavigation = (path) => {
    dispatch(setIsLoading(true));
    setTimeout(() => {
     dispatch(setIsLoading(false));
    },200);
    navigate(path);
  };
  const toggleMenu = () => {
    setIsMenuVisible(prev => !prev);
  };

  const fullName = useSelector((state) => state.auth.fullName);
 //console.log("User's Full Name:", fullName);


  const toggleSidebar = () => {
    setSidebarOpen(prev => !prev); 

    
  };
 
  const handleSignOut = () => {
    dispatch(setIsLoading(true));
    setTimeout(() => {
      dispatch(clearUser());
      localStorage.clear();
      sessionStorage.clear();
      dispatch(logout());
      toast.success('Logout successful!');
      dispatch(setIsLoading(false));
      navigate('/signin', { replace: true });
    }, 500); 
  };

  return (
    <header className="header-out">
      <SessionTimeout/>
      {isMobile &&
      <div className='mobile-title-out'>
       <img src={titleMobile} alt="Title" className="title-mobile" /> </div>}
            {!isMobile && 
      <div className='header-cont'>
        <div className='inner-header-elements'>
        <img src={logo} alt="Logo" className="logo" />
        
        <img src={title} alt="Title" className="title" />
      
          
        <div className="profile">
          <h3><span style={{ color: '#171717'}}>Welcome, </span><span style={{ color: '#5A189A', marginLeft: '6px' }}>{fullName}</span></h3>
          <button className="profile-button" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
          </button>
          {isMenuVisible && (
            <div className="dropdown-menu" onClickOutside={() => setSidebarOpen(false)}  >
              <div className="menu-item" onClick={() => handleNavigation('/home/manage-account')}>
            

                <span>Manage Account</span>
                <FontAwesomeIcon icon={faCog} className="menu-icon" />
              </div>
              <div className="menu-item"   onClick={() => handleNavigation('/home/change-password')}>
                <span>Change Password</span>
                <FontAwesomeIcon icon={faKey} className="menu-icon" />
              </div>
              <div className="menu-item" onClick={handleSignOut}>
                <span>Sign Out</span>
                <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" />
             
              </div>
            </div>
          )}
        </div>
        </div>
      </div> }
      <Headerunner/>
      {isMobile && (
              <div ref={sidebarRef}>
          <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} isMobile={isMobile} />
          </div>
      )}
    </header>
  );
};

export default Header;
