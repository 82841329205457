import React, { useState, useEffect,} from 'react';
import Priceupdates from './Priceupdates';
import './ManageAccount.css'; 
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from './LoadingOverlay';
import  EditOutline  from'./asset2/edit-outline.svg';
import { setIsLoading } from '../../Redux/store/authSlice';
const ManageAccount = () => {
  const [userName, setUserName] = useState('');
  const [fullName, setFullName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [initialData, setInitialData] = useState({});
  const [successMessage, setSuccessMessage] = useState(''); 
  const [buttonsDisabled, setButtonsDisabled] = useState(false); 
  //const [loading, setLoading] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const dispatch = useDispatch();

  const userToken = useSelector((state) => state.auth.token);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const apiUrl = process.env.REACT_APP_API_URL;
  const Gender = Object.freeze({
    Male: 0,
    Female: 1,
    PreferNotToSay: 2,
  });

  const userID = useSelector(state => state.auth.CustomerID);

  useEffect(() => {
    const fetchAccountData = async () => {
      dispatch(setIsLoading(true));
      try {
        const response = await fetch(`${apiUrl}/account/${userID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch account data');
        }

        const data = await response.json();
        setInitialData(data);
        setUserName(data.userName);
        setFullName(data.fullName);
        setCustomerId(data.id);
        setMobileNumber(data.mobileNumber.slice(-10))
        setEmail(data.email);
        setGender(data.gender);
      } catch (error) {
        console.error('Error fetching account data:', error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchAccountData();
  }, [userID, userToken, dispatch]);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateMobileNumber = (number) => {
    const mobilePattern =  /^\d{10}$/; 
    return mobilePattern.test(number);
  };


  const handleSaveChanges = async () => {
    let valid = true;
  
    if (!validateMobileNumber(mobileNumber)) {
      setMobileNumberError('Please enter a valid 10-digit mobile number.');
      valid = false;
    } else {
      setMobileNumberError(''); 
    }

    //if (!validateEmail(email)) {
      //alert('Please enter a valid email address.');
      //setEmailError('Please enter a valid email address.');
     // return;
    //}
    if (email && !validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

   
    if (!valid) {
      return;
    }

    const updateData = {
      id: customerId,
      userName: userName,
      fullName: fullName,
      mobileNumber: mobileNumber,
      email: email || null,
      gender: parseInt(gender),
    };
    dispatch(setIsLoading(true));
    try {
      const response = await fetch(`${apiUrl}/account/${userID}`, {
       // https://localhost:7040/api/account/28
      // https://localhost:7040/api/account/24
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        const errorData = await response.json(); 
  
        if (errorData?.message) {
          if (errorData.message.includes("Email already exists")) {
            setEmailError("Email already exists");
          }
          if (errorData.message.includes("Mobile Number already exists")) {
            setMobileNumberError("Mobile Number already exists");
          }
        } else {
          console.error("Failed to update account:", errorData);
        }
        return; 
      }
  
      setSuccessMessage('Your changes have been saved and updated successfully');
      setButtonsDisabled(true);
    } catch (error) {
      console.error('Error updating account data:', error);
    }finally {
      dispatch(setIsLoading(false)); 
    }
  };

  const discardChanges = () => {
    setUserName(initialData.userName);
    setFullName(initialData.fullName);
    setCustomerId(initialData.id);
    setMobileNumber(initialData.mobileNumber.slice(-10));
    setEmail(initialData.email);
    setGender(initialData.gender);
    setSuccessMessage(''); 
    setButtonsDisabled(false); 
  };

  //const onClickGoBack = () => {
   // navigate('/home/my-saving-scheme');
  //};

  return (
    <div className="whole-mysave-cont">
             {isLoading && <LoadingOverlay />}
      <Priceupdates />
      <div className='manage-account-out'>
       
        <div className='scheme-name-out-man'>
          <div className='scheme-name-cont-man'>
            <label htmlFor='user-name'>User Name</label>
            <input 
              type='text' 
              id='user-name' 
              className='form-input' 
              placeholder='User Name' 
              value={userName} 
              disabled 
            />
          </div>
          <div className='man-rhs'>
            <label htmlFor='full-name'>Full Name    <div className='edit-icon-outer'><h3>Edit</h3>  <img
           src={EditOutline}
             alt="Edit Icon"
              className='edit-icon-svg'
             /> </div></label>  
            <input 
                      type="text" 
              id="full-name" 
            className="form-input" 
           placeholder="Full Name" 
            value={fullName} 
            onChange={(e) => {
          const input = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(input)) {
              setFullName(input);
                 }
        }}
        />
          </div>
        </div>
        <div className='scheme-name-out-man'  >  
          <div className='scheme-name-cont-man'>
            <label htmlFor='customer-id'>Customer ID</label>
            <input 
              type='text' 
              id='customer-id' 
              className='form-input' 
              placeholder='Customer ID' 
              value={customerId} 
              disabled 
            />
          </div>
          <div className='man-rhs'>
            <label htmlFor='mobile-number'>Mobile Number <div className='edit-icon-outer'><h3>Edit</h3>  <img
           src={EditOutline}
             alt="Edit Icon"
              className='edit-icon-svg'
             /> </div></label>
            
            <input 
              type='text' 
              id='mobile-number' 
              className='form-input' 
              placeholder='Mobile Number' 
              value={`+91 ${mobileNumber}`}
              onChange={(e) => {
               
                const newValue = e.target.value.replace('+91 ', '');
                    
                if (/^\d{0,10}$/.test(newValue)) {
                  setMobileNumber(newValue);
                  setMobileNumberError(''); 
                } else {
                  setMobileNumberError('Please enter a valid 10-digit mobile number.');
                }
              }} 
            />
             {mobileNumberError && <div className="error-message">{mobileNumberError}</div>}
          </div>
        </div>
        <div className='scheme-name-out-man'>
          <div className='scheme-name-cont-man'>
            <label htmlFor='gender'>Gender <div className='edit-icon-outer'><h3>Edit</h3>  <img
           src={EditOutline}
             alt="Edit Icon"
              className='edit-icon-svg'
             /> </div></label>
            <select 
              id='gender' 
              className='form-input' 
              value={gender} 
              onChange={(e) => setGender(e.target.value)}
            >
              <option value=''>Select Gender</option>
              <option value={Gender.Male}>Male</option>
              <option value={Gender.Female}>Female</option>
              <option value={Gender.PreferNotToSay}>Prefer Not to Say</option>
            </select>
          </div>
          <div className='scheme-name-cont-man'>
            <label htmlFor='email'>Email  <div className='edit-icon-outer'><h3>Edit</h3>  <img
           src={EditOutline}
             alt="Edit Icon"
              className='edit-icon-svg'
             /> </div></label>
            <input 
              type='text' 
              id='email' 
              className='form-input' 
              placeholder='Email ID' 
              value={email} 
              onChange={(e) => {
                const inputValue = e.target.value;
                setEmail(inputValue);
                if (!inputValue) {
                  setEmailError('');
                }
              }}
            />
              {emailError && <div className="error-message">{emailError}</div>}
          </div>
        </div>
        <div className="manage-grp-btn">
          <button 
            type="button" 
            className="btn-btn-sav-chan"
            onClick={handleSaveChanges}
            disabled={buttonsDisabled}
          >
            Save changes
          </button>
          <button 
            className="btn-btn-discard"  
            onClick={discardChanges}
            disabled={buttonsDisabled} 
          >
            Discard
          </button>
        </div>
        {successMessage && <div className="success-message-manage-account">{successMessage}</div>} 
      </div>
    </div>
  );
};

export default ManageAccount;
