

import React, { useState, useEffect } from 'react';


import '@fortawesome/fontawesome-free/css/all.min.css';
import WelcomePage from './WelcomePage';

import CopyRightSigin from './CopyRightSigin';

import Footer from '../common/Footer';

import AuthForm from './AuthForm';
import MobSignIn from './MobSignIn';

//const Roles = {
  //Admin: 0,
 // User: 1,
//};
const Signin = () => { 
  //const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 576); 
      setIsTablet(width > 576 && width <= 1133); 
      setIsDesktop(width >= 1134); 
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
       {isMobile && <MobSignIn />}
     {!isMobile && <div className= 'sign-in-out'>
       {isDesktop && <WelcomePage />}
 
      <div className="auth-container">
             <div className='auth-form-out'>
     <AuthForm/>
        
      {isMobile && !isTablet && <Footer />}
        {!isMobile &&  !isTablet &&
        <div className='copy-outer-cont'> 
       <CopyRightSigin/> </div> }
      </div>
      </div>
           </div> }  
    </>

   
  );
};

export default Signin;