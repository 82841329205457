import React, { useState } from 'react'; 
import CustManCards from './CustManCards';
import './CloseScheme.css';
import { useNavigate } from 'react-router-dom';
import CloseMemSch from './CloseMemSch';

const CloseScheme = () => {
  const [showCloseMemSch, setShowCloseMemSch] = useState(false); 
  const navigate = useNavigate();

  const viewdetailhandleClick = () => {
    navigate('/home/view-detail-scheme');
  };

  const onClickGoBack = () => {
    navigate('/admin/home/viewing-user-profile');
  };

  const handleProceedClick = (e) => {
    e.preventDefault(); 
    setShowCloseMemSch(true); 
  };

  return (
    <>
      {showCloseMemSch ? (
        <CloseMemSch /> 
      ) : (
        <div className="whole-mysave-cont">
          <CustManCards />
          <div className='close-use-out'>
            <div className='close-use-cont'>Close a User's Scheme</div>
            <form>
              <div className='close-form'>
                <label>
                  Full Name
                  <input type="text" name="username" placeholder="Enter User Name or Mobile Number" />
                </label>

                <label>
                  Customer ID
                  <input type="text" name="customerId" placeholder="Enter your ID" />
                </label>
              </div>

              <div className='close-form'>
                <label>
                  Mobile Number
                  <input type="text" name="username" placeholder="Enter User Name or Mobile Number" />
                </label>

                <label>
                  Email (optional)
                  <input type="text" name="customerId" placeholder="Enter your ID" />
                </label>
              </div>

              <button className="close-proceed" onClick={handleProceedClick}>
                Proceed
              </button>
            </form>
          </div>

          
        </div>
      )}
    </>
  );
};

export default CloseScheme;
