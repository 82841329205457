
import React from 'react'
import flowericon from './asset1/flower.svg';
const Headerunner = () => {
  return (
    <div className='display-runner-out'>
        <div className='display-runner-inner'>
          
          <img src={flowericon} alt="Flower-Icon" className="flower-icon" />
          <h3>Delayed payments will delay the maturity period</h3>
          <img src={flowericon} alt="Flower-Icon" className="flower-icon" />
          <h3>Jewels can be purchased 30 days after last payment</h3>

          <img src={flowericon} alt="Flower-Icon" className="flower-icon" />
          <h3>Delayed payments will delay the maturity period</h3>
          <img src={flowericon} alt="Flower-Icon" className="flower-icon" />
          <h3>Jewels can be purchased 30 days after last payment</h3>

          <img src={flowericon} alt="Flower-Icon" className="flower-icon" />
          <h3>Delayed payments will delay the maturity period</h3>
          <img src={flowericon} alt="Flower-Icon" className="flower-icon" />
          <h3>Jewels can be purchased 30 days after last payment</h3>
         
         
        </div>


      </div>
  )
}

export default Headerunner
