import React, { useState, useEffect } from 'react';
import './InstallmentsCont.css';
import rupeeicon from './asset2/rupee-icon.png';
import violeteye from './asset2/violet-eye.png';
import downarrow from './asset2/down-arrow.png';
import TransactDet from './TransactDet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PaymentMethodEnum = {
  0: 'None',
  1: 'InternetBanking',
  2: 'UPI',
  3: 'Card',
  4: 'Wallet',
  5: 'CreditCard',
  6: 'DebitCard',
  7: 'BankTransfer',
  8: 'OnlinePayment',
  9: 'Cash',
  10: 'Check',
};

const PaymentStatusEnum = {
  0: 'Created',
  1: 'Authorized',
  2: 'Captured',
  3: 'Refunded',
  4: 'Failed',
  5: 'Skipped',
};

const InstallmentsCont = ({ transactionData }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <=576);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const handleOpenPopup = (paymentTransactionId) => {
//    console.log("Payment Transaction ID:", paymentTransactionId);
    setSelectedTransactionId(paymentTransactionId);
    setShowPopup(true);
  };



  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedTransactionId(null);
  };

  return (
    <div className='clearfix'>
    <div className="table-container">
      <table className="installment-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Paid Date</th>
            <th>Status</th>
            {!isMobile &&   <th>Mode of Payment</th>}
            <th>More Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactionData.map((installment, index) => (
            <tr key={index}>
              <td>{installment.installments}</td>
              <td>
                {installment.datePaid
                  ? new Date(installment.datePaid).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                  : '-'}
              </td>
            
                <td
                  className={installment.status}
                  style={{
                    color: installment.paymentStatus === 1 ? '#45A145' : '#E51A1A',
                    fontSize: '1.125rem',
                    fontWeight: 700,
                  }}
                >
                  {PaymentStatusEnum[installment.paymentStatus]}
                </td>
         
       
             {!isMobile && <td>{PaymentMethodEnum[installment.paymentMethod]}</td>  }
              <td>
              {/*<button className="action-button rup-btn">
                  <img src={rupeeicon} alt="Rupee-Icon" className="rupee-icon" />
                </button> */}  
                <button className="action-button eye-btn" onClick={() => handleOpenPopup(installment.paymentTransactionId)} disabled={installment.paymentStatus !== 1}>
                  <img src={violeteye} alt="Eye-icon" className="violet-eye" />
                </button>
                <button className="action-button down-btn"   disabled={installment.paymentStatus !== 1}>
                  <img src={downarrow} alt="Down-arrow" className="down-arrow" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <TransactDet paymentTransactionId={selectedTransactionId} />
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon-trans"
              onClick={handleClosePopup}
            />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default InstallmentsCont;
