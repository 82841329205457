import React from 'react'
import CustManCards from './CustManCards'
import CustomerManData from './CustomerManData'

const CustomerMan = () => {
  return (
    <div className="whole-mysave-cont">
      <CustManCards/>
      <CustomerManData/>
      </div>
  )
}

export default CustomerMan
