import React, { useState, useEffect } from 'react';
import pageCalendar from './asset2/calendar.png';
import goldIcon from './asset2/gold.png';
import silverIcon from './asset2/silver.png';
import platinumIcon from './asset2/platinum.png'; 
import { useSelector } from 'react-redux';
//import * as signalR from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

const Priceupdates = () => {
  const [rates, setRates] = useState({});
  const userToken = useSelector(state => state.auth.token);
  const [newToken, setNewToken] = useState(null);
  const [connection, setConnection] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const goldapiUrl = process.env.REACT_APP_API_URL_GOLD;

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await fetch(`${apiUrl}/commodityrates`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRates(data);
        const receivedNewToken = data.token; 
        if (receivedNewToken) {
          setNewToken(receivedNewToken);
        }
       // console.log('Extrernal Token : ', receivedNewToken)
        if (receivedNewToken) {
          const newConnection = new HubConnectionBuilder()
            .withUrl(`${goldapiUrl}/pricehub`, {
              accessTokenFactory: () => receivedNewToken
            })
            .withAutomaticReconnect()
            .build();

          setConnection(newConnection);

          await newConnection.start().then(() => console.log('Connected to SignalR hub'))
          .catch(err => console.error('Error connecting to hub:', err));
          
          newConnection.on('ReceiveMessage', (priceData) => {
            console.log('Price update received:', priceData);
            setRates(prevRates => ({
              ...prevRates,
              ...priceData // Update rates with new data
            }));
          });
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchRates();
    
  }, [userToken]);
  const currentDate = formatDate(new Date());
  const imageData = [
    { src: pageCalendar, alt: 'page-calendar', label: 'Todays Date', value: currentDate },
    { src: goldIcon, alt: 'gold-icon', label: 'Gold Rate', value: `₹ ${rates.gold || '0.00'}` },
    { src: silverIcon, alt: 'silver-icon', label: 'Silver Rate', value: `₹ ${rates.silver || '0.00'}` },
    { src: platinumIcon, alt: 'platinum-icon', label: 'Platinum Rate', value: `₹ ${rates.platinum || '0.00'}` },
  ];

  return (
    <div className="price-updates">
      {imageData.map((item, index) => (
        <div className="update-item" key={index}>
          <img src={item.src} alt={item.alt} className="update-icon" />
          <div className="update-info">
            <p className="update-label">{item.label}</p>
            <h3 className="update-value">{item.value}</h3>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Priceupdates;
