import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword2.css';
import Priceupdates from './Priceupdates';
import { Icon } from '@iconify/react'; 
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch  } from 'react-redux';
import LoadingOverlay from './LoadingOverlay';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Error as ErrorIcon } from '@mui/icons-material';
import warningIcon from '../common/asset1/warning-symbol.svg';
import { setIsLoading} from '../../Redux/store/authSlice';
import { clearUser } from "../../Redux/store/authSlice";
const ForgotPassword2 = () => {
  const [loginType, setLoginType] = useState('otp');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  //const [loading, setLoading] = useState(false);
  const otpRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    number: false,
    noSpaces: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false); 
  //console.log("Authorization Token:", token);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading); 
  
  const prefix = '+91';
  const handleMobileNumberChange = (e) => {
    const inputValue = e.target.value.replace(prefix, ''); 
    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) { 
      setMobileNumber(inputValue);
    }
  };
 const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    fetchIPAddress();
  }, []);
  useEffect(() => {
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);
  const validateMobileNumber = (mobile) => /^[0-9]{10}$/.test(mobile);
useEffect(() => {
    const validatePasswordRequirements = () => {
        setPasswordValidations({
            length: password.length >= 8 && password.length <= 20,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*]/.test(password),
            noSpaces: !/\s/.test(password),
        });
    };
    validatePasswordRequirements();
}, [password]);

 
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    dispatch(setIsLoading(true));
    if (loginType === 'otp' && !otpSent) {
      if (!validateMobileNumber(mobileNumber)) {
        setError('Invalid Mobile Number. It must contain exactly 10 digits.');
        return;
      }
      dispatch(setIsLoading(true));
      try {
        const response = await axios.post(`${apiUrl}/otp`, {
          mobileNo: mobileNumber,
        });

        if (response.data.status === 'Success') {
          setSessionID(response.data.details);
          setOtpSent(true);
          setError('');
          dispatch(setIsLoading(false));
        } else {
          setError('Failed to send OTP. Please try again.');
          dispatch(setIsLoading(false));
        }
      }  catch (error) {
        if (error.response) {
         
          if (error.response.status === 400) {
            setError(error.response.data.message || 'Bad Request');
          } else if (error.response.status === 401) {
            if (error.response.data.message === "Invalid username or password") {
              setError('Invalid Username or Password. Please try again.');
            } else {
              setError('Invalid OTP. Please try again.');
            }
          } else {
            setError('Something went wrong. Please try again.');
          }
        } else {
          setError('Network error. Please check your connection.');
        }
        dispatch(setIsLoading(false))
        ;}
      }
    };
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== '' && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
  

      if (value === '' && index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join('');
    if (enteredOtp.length === 6) {
     
      try {
        dispatch(setIsLoading(true));
        const response = await axios.post(`${apiUrl}/otp/verify`, {
          otp: enteredOtp,
          sessionID,
          mobileNumber,
          ipAddress,
        });

        if (response.data === true) {
          setOtpVerified(true);
          setError('');
        } else {
          setError('Invalid OTP or session expired.');
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        setError('Something went wrong. Please try again.');
      } finally {
        dispatch(setIsLoading(false));
      }
    } else {
      setError('Please enter a 6-digit OTP.');
    }
  };
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8 && password.length <= 20;
    const upperCaseCheck = /[A-Z]/.test(password);
    const lowerCaseCheck = /[a-z]/.test(password);
    const digitCheck = /[0-9]/.test(password);
    const specialCharCheck = /[!@#$%^&*]/.test(password);

    if (!lengthCheck) return 'Password must be 8-20 characters long.';
    if (!upperCaseCheck) return 'Password must contain at least one uppercase letter.';
    if (!lowerCaseCheck) return 'Password must contain at least one lowercase letter.';
    if (!digitCheck) return 'Password must contain at least one number.';
    if (!specialCharCheck) return 'Password must contain at least one special character.';
    return null;
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setConfirmPasswordError('');
    dispatch(setIsLoading(true));

    const passError = validatePassword(password);
    if (passError) {
      setPasswordError(passError);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }
    dispatch(setIsLoading(true));
    try {
      const response = await axios.post(`${apiUrl}/account/forgotpasswordforuser`, {
        sessionID,
        otp: otp.join(''),
        newPassword: password,
        mobileNumber,
        confirmPassword,
        ipAddress,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data && response.data.includes('Password Update successful')) {
        setError('');
        setPasswordChanged(true);
        dispatch(setIsLoading(false));
        dispatch(clearUser());
        localStorage.clear();
        setTimeout(() => {
          navigate('/signin');
        }, 5000);
      } else {
        setError('Failed to change password. Please try again.');
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      if (error.response) {
      
        if (error.response.status === 400) {
          setError(error.response.data.message || 'Bad Request');
        } else if (error.response.status === 401) {
          if (error.response.data.message === "Invalid username or password") {
            setError('Invalid Username or Password. Please try again.');
          } else {
            setError('Invalid OTP. Please try again.');
          }
        } else {
          
          setError(error.response.data.message || 'Something went wrong. Please try again.');
        }
      } else {
       
        setError('Network error. Please check your connection.');
      }
      dispatch(setIsLoading(false));
    }
  };

  const onClickGoBack = () => {
    dispatch(setIsLoading(true));
    setTimeout(() => {
      navigate('/home/change-password');
      dispatch(setIsLoading(false));
    }, 200); 
  };

  return (
    <div className='man-acc-out'>
      <Priceupdates />
      <div className='man-acc-cont'>
  
        <button className='go-back-cont' onClick={onClickGoBack}disabled={isLoading}> 
          <Icon icon="iconamoon:arrow-left-2-bold" className='go-back-arrow'/>Go Back
        </button>
        <div className="change-pass-auth-form">
          {!otpVerified ? (
            <form onSubmit={handleSubmit}>
              <div className='form-group-forgot-password'>
                <label>Enter your registered mobile number to receive an OTP:</label>
                <input
                  type="text"
                  value={`${prefix}${mobileNumber}`}
                  onChange={handleMobileNumberChange} 
                  maxLength={13}
                  required
                  placeholder="10-digit mobile number"
                />
                <button type="submit" className="btn btn-reset-forgot-password">Send OTP</button>
              </div>

              {otpSent && (
                <div className='form-group-forgot-password'>
                  <label>A message with the OTP has been sent to your provided mobile number</label>
                  <div className='otp-input-container'>
                    {[...Array(6)].map((_, i) => (
                      <input
                        key={i}
                        type="text"
                        value={otp[i] || ''}
                        onChange={(e) => handleOtpChange(e, i)}
                        maxLength={1}
                        ref={(el) => (otpRefs.current[i] = el)}
                        className="otp-input"
                        required
                      />
                    ))}
                  </div>
                  <button type="button" className="btn btn-verify" onClick={handleVerifyOtp}>Verify OTP</button>
                </div>
              )}
              {error &&    <div className="error-with-icon-out">
                <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
                <div className='error-message-forgotting-password'>{error}</div></div>}
            </form>
          ) : (
            <form onSubmit={handlePasswordChange}>
               {!passwordChanged ? (
                <>
              <div className='form-group-forgot-password'>
              <h3>Change Password</h3>
                <div className='form-group-row'>
                  <label>New Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={handlePasswordFocus}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={() => setShowPassword(!showPassword)}
                    className="password-icon"
                  />
                  {passwordError && <div className="error-message-forgotting-password">{passwordError}</div>}
                </div>
                <div className='form-group-row'>
                  <label>Confirm Password</label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="password-icon"
                  />
                   {isPasswordFocused && (
        <div className="password-validation">
          {confirmPassword.length > 1 && (
          <div style={{ color: passwordsMatch ? '#45A145' : '#E51A1A' }}>
            {passwordsMatch ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#E51A1A', marginRight: '8px' }} />
            )}
            {passwordsMatch ? "Passwords match" : "Passwords do not match"}
          </div>
          )}
          <div style={{ color: passwordValidations.length ? '#45A145' : '#808080' }}>
            {passwordValidations.length ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' }} />
            )}
            Password must be at least 8 characters long
          </div>
          <div style={{ color: passwordValidations.uppercase ? '#45A145' : '#808080', display: 'flex', alignItems: 'center'}}>
            {passwordValidations.uppercase ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px', verticalAlign: 'middle' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' , verticalAlign: 'middle'  }} />
            )}
            Password must contain at least one uppercase letter
          </div>
          <div style={{ color: passwordValidations.number ? '#45A145' : '#808080' }}>
            {passwordValidations.number ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must contain at least one number
          </div>
          <div style={{ color: passwordValidations.noSpaces ? '#45A145' : '#808080' }}>
            {passwordValidations.noSpaces ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must not contain spaces
             </div>
               </div> )}
                  {confirmPasswordError && <div className="error-message-forgotting-password">{confirmPasswordError}</div>}
                </div>
              </div>
              <button type="submit" className="btn-frt btn-reset-forgot-passsword">Change Password</button>
              </>
              ) : (
                <div className="success-message">
                 "Your password has been successfully changed. You will be redirected to the Login page shortly."
                </div>
              )}
            </form>
          )}
        </div>
      </div>
     
    </div>
  );
};

export default ForgotPassword2;
