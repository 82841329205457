import React, { useEffect, useState } from 'react';
import Priceupdates from './Priceupdates';
import './ViewDetailsMySaving.css';
import { useNavigate, useLocation } from 'react-router-dom'; 
import MemberCons from './MemberCons';
import InstallmentsCont from './InstallmentsCont';
import { Icon } from '@iconify/react';
import { useSelector , useDispatch } from 'react-redux';
import LoadingOverlay from './LoadingOverlay';
import { setIsLoading } from '../../Redux/store/authSlice';
const ViewDetailsMySaving = () => {
  const [schemeData, setSchemeData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  //const [isLoading, setIsLoading] = useState(true);
  const userToken = useSelector(state => state.auth.token);
  const userID = useSelector(state => state.auth.CustomerID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); 
  const customerEnrolledSchemeId = location.state?.customerEnrolledSchemeId; 
  const apiUrl = process.env.REACT_APP_API_URL;
  const isLoading = useSelector(state => state.auth.isLoading);
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setIsLoading(true));
        const transactionResponse = await fetch(
          `${apiUrl}/enrolledscheme/customerenrolledschemetransaction/${customerEnrolledSchemeId}`, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (!transactionResponse.ok) throw new Error('Failed to fetch transaction data');
        
        const transactionData = await transactionResponse.json();
        setTransactionData(transactionData.transactionHistories || []); 
        setSchemeData(transactionData); 
        dispatch(setIsLoading(false));
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(setIsLoading(false));
      }
    };

    if (customerEnrolledSchemeId) {
      fetchData();
      //console.log("ViewDetailsMySaving customerEnrolledSchemeId:", customerEnrolledSchemeId);
    }
  }, [userID, userToken, customerEnrolledSchemeId]);

  const onClickGoBack = () => {
    navigate('/home/my-saving-scheme');
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }
  return (
    <div className='view-det-out'>
      <Priceupdates />
      <div className='man-cont-out-mobile'>
      <button className='go-back-cont' onClick={onClickGoBack}>
        <Icon icon="iconamoon:arrow-left-2-bold" className='go-back-arrow'/>Go Back
      </button>

      {schemeData && <MemberCons schemeData={schemeData} />}
      <div className='inst-out-cont'>
        <InstallmentsCont transactionData={transactionData} />
      </div> </div>
    </div>
  );
};

export default ViewDetailsMySaving;
