import React, { useState,useEffect } from 'react';
import './ChangePassword.css';
import Priceupdates from './Priceupdates';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@iconify/react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay'; 
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Error as ErrorIcon } from '@mui/icons-material';
import warningIcon from '../common/asset1/warning-symbol.svg';
import { setIsLoading } from '../../Redux/store/authSlice';
const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  //const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading); 
  console.log("isLoading state:", isLoading);
  const [ipAddress, setIpAddress] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    number: false,
    noSpaces: false,
  });
  const [passwordsMatch,setPasswordsMatch] = useState('');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false); 
   
  const apiUrl = process.env.REACT_APP_API_URL;
  const userToken = useSelector((state) => state.auth.token);
//console.log('Change and New change Password token from Redux:', userToken);
useEffect(() => {
  const fetchIPAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      setIpAddress(response.data.ip);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };
  fetchIPAddress();
}, []);

const handleNavigation = (path) => {
  dispatch(setIsLoading(true));
  setTimeout(() => {
   dispatch(setIsLoading(false));
  },200);
  navigate(path);
};
  const userID = useSelector(state => state.auth.CustomerID);
 //    console.log("Manage account userID:",userID)
  const onClickGoBack = () => {
    navigate(`/home/my-saving-scheme`);
  };
 
  useEffect(() => {
    setPasswordsMatch(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);
  
  useEffect(() => {
    const validatePasswordRequirements = () => {
      setPasswordValidations({
        length: newPassword.length >= 8,
        uppercase: /[A-Z]/.test(newPassword),
        number: /[0-9]/.test(newPassword),
        noSpaces: !/\s/.test(newPassword),
      });
    };
    validatePasswordRequirements();
  }, [newPassword]);
  

  const validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    if (/\s/.test(password)) {
      return 'Password must not contain spaces';
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return 'Password must contain at least one special character';
  }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    let valid = true;

    
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    
    console.log('Current Password:', currentPassword);
    console.log('New Password:', newPassword);
    console.log('Confirm Password:',confirmPassword)

    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setNewPasswordError(passwordError);
      valid = false;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      valid = false;
    }

    if (!valid) return;
    dispatch(setIsLoading(true));
    try {
     
    
      const response = await fetch(`${apiUrl}/account/changepassword?id=${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
         // id: userId,
         // userName: userName,
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          ipAddress,
        }),
      });
       dispatch(setIsLoading(false));
      if (response.ok) {
        setSuccess(true); 
        //alert('Your password has been changed and updated successfully');
        setError('');
        navigate('/home/change-password');
      } else {
        //const errorData = await response.json();
        //console.error('Error Response:', errorData);
        //if (errorData.errors) {
        //  setError(`Error: ${Object.values(errorData.errors).flat().join(', ')}`);
        const errorText = await response.text(); 
        console.error('Error Response:', errorText);

        
        if (errorText.includes('Current password does not match')) {
          setError('Current password does not match');

          

        } else {
          setError('Failed to change password');
        }
      }
    } catch (err) {
      console.error('Error:', err);
      alert('An unexpected error occurred');
      dispatch(setIsLoading(false));
    }
  };


  const toggleShowPassword = (setShowPassword) => {
    setShowPassword((prevState) => !prevState);
  };
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  return (
    <div className='man-acc-out'>
            {isLoading && <LoadingOverlay />}
      <Priceupdates />
      <div className='man-acc-cont'>
        <div className="change-pass-auth-form">

          {success ? (<div className="success-message">
              <FontAwesomeIcon  icon={faCheckCircle} className="success-icon" />
              <p>Your password has been changed and updated successfully</p>
        <button className='go-back-cont-pass-con' onClick={onClickGoBack}> <Icon icon="iconamoon:arrow-left-2-bold" 
        className='go-back-arrow-pass-con'/>Go Back</button> </div>):
        (<>
          <h3>Change Password</h3>
          <form onSubmit={handleSubmit}>
            <div className="change-pass-form-group">
              <label>Current Password</label>
              <div className="input-password-container">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showCurrentPassword ? faEyeSlash : faEye}
                  onClick={() => toggleShowPassword(setShowCurrentPassword)}
                  className="password-icon"
                />
                
              </div>
              {error && <div className="error-reg-icon-out">  <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
               <p className="error-changing-password">{error}</p></div>}
              {currentPasswordError && <div className="error-with-icon-out">  <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
               <p className="error-changing-password">{currentPasswordError}</p> </div>}
            </div>
            <div className="change-pass-form-group">
              <label>New Password</label>
              <div className="input-password-container">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onFocus={handlePasswordFocus}
                  required
                />
                <FontAwesomeIcon
                  icon={showNewPassword ? faEyeSlash : faEye}
                  onClick={() => toggleShowPassword(setShowNewPassword)}
                  className="password-icon"
                />
              </div>
              {newPasswordError && <div className="error-reg-icon-out">  <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
              <p className="error-changing-password">{newPasswordError}</p></div>}
            </div>
            <div className="change-pass-form-group">
              <label>Confirm Password</label>
              <div className="input-password-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  onClick={() => toggleShowPassword(setShowConfirmPassword)}
                  className="password-icon"
                />
              </div>
              {confirmPasswordError && <div className="error-reg-icon-out">  <img src={warningIcon} alt="Warning-Icon" className="warning-icon" />
               <p className="error-changing-password">{confirmPasswordError}</p> </div>}
              {isPasswordFocused && (
              <div className="password-validation">
                    {confirmPassword.length > 1 && (
          <div style={{ color: passwordsMatch ? '#45A145' : '#E51A1A' }}>
            {passwordsMatch ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#E51A1A', marginRight: '8px' }} />
            )}
            {passwordsMatch ? "Passwords match" : "Passwords do not match"}
          </div>
                    )}
          <div style={{ color: passwordValidations.length ? '#45A145' : '#808080' }}>
            {passwordValidations.length ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' }} />
            )}
            Password must be at least 8 characters long
          </div>
          <div style={{ color: passwordValidations.uppercase ? '#45A145' : '#808080', display: 'flex', alignItems: 'center'}}>
            {passwordValidations.uppercase ? (
              <CheckCircleIcon style={{ color: '#45A145' , marginRight: '8px', verticalAlign: 'middle' }} />
            ) : (
              <ErrorIcon style={{ color: '#808080', marginRight: '8px' , verticalAlign: 'middle'  }} />
            )}
            Password must contain at least one uppercase letter
          </div>
          <div style={{ color: passwordValidations.number ? '#45A145' : '#808080' }}>
            {passwordValidations.number ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must contain at least one number
          </div>
          <div style={{ color: passwordValidations.noSpaces ? '#45A145' : '#808080' }}>
            {passwordValidations.noSpaces ? (
              <CheckCircleIcon style={{ color: '#45A145', marginRight: '8px' }} />
            ) : (
              <CancelIcon style={{ color: '#808080', marginRight: '8px'  }} />
            )}
            Password must not contain spaces
             </div>
               </div> )}
            </div>
           
            <div className="change-pass-grp-btn">
              <button type="submit" className="btn-btn-change">Change password</button>
              <Link to="/home/forgot-password2" className="btn-btn-forgot" onClick={() => handleNavigation('/home/forgot-password2')}>Forgot password?</Link>
            </div>
          </form> </>)}
        </div>
      </div>
        </div>
  );
};

export default ChangePassword;
