import React, { useState, useEffect, useCallback } from 'react'
import './Home.css';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidebar from '../common/Sidebar';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Home = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1133);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  //const user = useSelector((state) => state.auth.user);
 // const isAdmin = useSelector((state) => state.auth.isAdmin);
 const toggleSidebar = () => {
  setSidebarOpen(!sidebarOpen);
}
  return (
    <>
    
    <Header toggleSidebar={toggleSidebar} />
  
    <div className='main-home-container'>
      
     {!isMobile && (<Sidebar/> )}
      <div className='whole-home-plus-footer'>
      <div className='whole-home-content'>
   
        <Outlet/>
        {!isMobile  && <Footer />} 
   {isMobile &&<Footer/> }
      </div>
    
            
     {isMobile && <Sidebar/> }
      </div>
     </div>
    
   </>
  )
}

export default Home
