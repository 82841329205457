import React, { useState, useEffect } from 'react'; 
import carousel1 from '../common/asset1/carousel1.png';
import carousel2 from '../common/asset1/carousel2.png';
import carousel3 from '../common/asset1/carousel3.png';
import rightarrow from '../common/asset1/rightarrow2.png';
import suitcase from '../common/asset1/suite-case.png';
import clock from '../common/asset1/clock.png';

 const isMobile = window.innerlength <= 1024;
 
const items = [
  {
    image: carousel1,
    title: 'Easy Registration',
    text: 'Sign up quickly with your mobile number and start managing your chit funds in no time.',
    icon: rightarrow,
  },
  {
    image: carousel2,
    title: 'Secure Transactions',
    text: 'Enjoy safe and secure transactions with our robust security measures protecting your data.',
    icon: suitcase,
  },
  {
    image: carousel3,
    title: 'Real-Time Updates',
    text: 'Stay informed with real-time updates on your chit fund status, payments, and more.',
    icon: clock,
  }
];

const WelcomePage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <div className='welcome-cont-out'>
      <div className='wel-cont'>
        <h3>Welcome to Ram Thanganagai Maligai</h3>
        <p>Join our community and manage your chit funds effortlessly. Enjoy secure and convenient access to all your financial needs.</p>
        <div className='carousel-outer-cont-all'>
        <div className="cover-photo-cont">
          <img src={items[currentIndex].image} alt="CoverPhoto" className="carousel" />
        </div>
        <div className='easy-reg-out'>
          <div className='easy-reg-cont'>
            <div className='easy-reg-icon-cont'>
              <img src={items[currentIndex].icon} alt="Icon" className="easy-reg-icon" />
            </div>
            <div className='easy-reg-details'>
              <h4>{items[currentIndex].title}</h4>
              <p>{items[currentIndex].text}</p>
            </div>
          </div>
        </div>
        <div className="dot-indicator">
          {items.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? 'active' : ''}`}
            ></span>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
