

import React, { useState } from 'react';
import Priceupdates from '../../components/pages/Priceupdates';
import './CustManCards.css';
import newUserIcon from './asset2/new-user.png';
import existUserIcon from './asset2/exist-user.png';
import closeUserIcon from './asset2/close-user.png';
import deactivateUserIcon from './asset2/deact-user.png';
import { Link, useNavigate } from 'react-router-dom';
import AddSchemePop from './AddSchemePop';

const CustManCards = () => {
  const [showPopup,setShowPopup]=useState(false);
  const navigate=useNavigate();

  const handleCardClick=(path)=> {
    if (path === '/admin/home/add-new-scheme') {
      setShowPopup(true);
    } else {navigate(path);}
  } 
 
  const handleProceed = () => {
    setShowPopup(false);
    navigate('/admin/home/add-new-scheme');
  }
  const cards = [
    { className: 'user-account', text: 'Create a new user account', icon: newUserIcon, 
      path:'/admin/home/new-user-reg' },
    { className: 'existing-user', text: 'Add a new scheme for existing user', icon: existUserIcon,
path:'/admin/home/add-new-scheme'
    },
    { className: 'close-user', text: 'Close a user’s scheme', icon: closeUserIcon,
      path:'/admin/home/close-user-scheme'
     },
    { className: 'deactivate-user', text: 'Deactivate a user account', icon: deactivateUserIcon ,
      path:'/admin/home/deactivate-user'
    },
  ];


  return (
    <>
      <Priceupdates />

      <div className="cards-cont-out">
        {cards.map((card, index) => (
          <div key={index} className={`color-card ${card.className}`} onClick={() => handleCardClick(card.path)}>
            <div className="text">{card.text}</div>
            <img src={card.icon} alt={`${card.text} icon`} className="icon-bottom-right" />
          </div>
        ))}
      </div>
<AddSchemePop show={showPopup} onClose={() => setShowPopup(false)} onProceed={handleProceed}/>
      
     
      </>
  );
};

export default CustManCards;
